import React, { Component } from 'react'
import UsuarioService from '../services/UsuarioService'
import DependenciaService from '../services/DependenciaService'
import ReservacionService from '../services/ReservacionService'
import ReservacionMasterService from '../services/ReservacionMasterService'
//-----
import 'jquery/dist/jquery.min.js';
//Datatable Modules
//import "datatables.net-dt/js/dataTables.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.min.css"
//import $ from 'jquery'; 
//-----
import 'jquery/dist/jquery.min.js';
//Datatable Modules
//import "datatables.net-dt/js/dataTables.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';


const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
`;

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;

const Spinner = styled.div`
  border: 3px solid orangered;
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite ${spin};
`;



class ListReservacionMasterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                reservacionesMaster: [], usuarios: [], optionsClientes: [], isLoadData : false
        }
        this.addReservacionMaster = this.addReservacionMaster.bind(this);
        this.editReservacionMaster = this.editReservacionMaster.bind(this);
        this.deleteReservacionMaster = this.deleteReservacionMaster.bind(this);
    }

    deleteReservacionMaster(id){
		alert('el id borrar '+id)
		//alert('usuarios => ' + JSON.stringify(this.state.usuarios));
		

		ReservacionMasterService.deleteReservacionMaster(id).then( res => {
			//alert ('aca2')
			window.location.reload(false);
            //this.props.history.push('/usuarios');
			//alert('aca 4')
        });
		alert('aca3');
		
    }
    viewReservacionMaster(id){
        this.props.history.push(`/view-reservacionMaster/${id}`);
    }

    editReservacionMaster(id, idCliente){
	   // alert('cccc');
		     	let cli = JSON.stringify(idCliente)
	    		console.log ('llega99999999999999999999 '+cli)
		   
        this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
    }


	fetchReservacionesMaster = async () => {
		        const res = await fetch("http://localhost:8080/api/v1/reservacionesMaster");
		        const data = await res.json();
				console.log('XXXXXXX'+JSON.stringify(data));
		        try {
				            this.setState({reservacionesMaster: data.results});
				        } catch (err) {
						            console.log(err);
						        }
				console.log('XXXXXXXyyyyyzzzz'+JSON.stringify(data));
				return data;
		    };


    componentDidMount(){
	    ReservacionMasterService.getReservacionesMaster().then((res) => {
            this.setState({ reservacionesMaster: res.data, isLoadData : true});
			$('#example').DataTable(
			{
			"order": [[ 0, "desc" ]]
			,"bDestroy": true
			}
			);
       
		});
	   // this.fetchReservaciones();
/*	
	    UsuarioService.getUsuarios().then((res)=> {
			this.setState({ usuarios: res.data});
		})
*/		
	
		//initialize datatable
	    //console.log('MMMMMNNNNNNNNMMMMM'+JSON.stringify(this.state.reservaciones));
		//$(document).ready(function () {
			setTimeout(function(){
			$('#example').DataTable(
			{
			"order": [[ 0, "desc" ]]
			,"bDestroy": true
			}
			);
			 } ,8000);
		//});
    }
	findUsuarioById(id_usu){
		let usuario = 'N.A.';
		for (let i=0;i<this.state.usuarios.length;i++){
			if (this.state.usuarios[i].id_usuario === id_usu){
				usuario = this.state.usuarios[i];
				break;
			}
		}
		return usuario;
	}


    addReservacionMaster(){
		//alert('iiiiii');
		let id='_add';
		let cli='{"identificacion":"","razon_social":"","id_usuario":"**"}';
		console.log('USU '+cli)
           
        this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
    
    }

    render() {
		
        return (
            <div>
                 <h2 className="text-center">Reservaciones</h2>
                 <div className = "row">
                    <button className="btn btn-primary" onClick={this.addReservacionMaster}> Nueva reservacion</button>
                 </div>
                 <br></br>
                 <div className = "row">
                      
					  
					  {
						 !this.state.isLoadData?<Spinner />:
						  
						<table id="example" className="table table-striped table-bordered dt-responsive nowrap display compact" style={{ width:100 +'%', "font-size": "0.90em" }}>
		  

                            <thead>
                                <tr>
									<th> Num </th>
                                 
									
                                    <th> Cliente</th>
                                    <th> Checkin</th>
                                    <th> Checkout</th>
									<th> Estado</th>
									<th> Valor</th>
									<th> Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.reservacionesMaster.map(
                                        reservacionMaster => 
                                        <tr key = {reservacionMaster.idReservacionMaster}>
											<td> {reservacionMaster.idReservacionMaster} </td> 
											
											
											 <td> {reservacionMaster.cliente.razon_social} </td>  
                                             <td> {reservacionMaster.fechaIni} </td>   
                                             <td> {reservacionMaster.fechaFin}</td>
											  <td> {
												  reservacionMaster.estadoReservacion.idEstadoReservacion===1?
												  <span style={{"color":  "#29B6F6", "padding": "1px 1px 1px 1px","font-weight": "bold", "font-size": "0.90em"}}>{reservacionMaster.estadoReservacion.estado}</span>
												  :reservacionMaster.estadoReservacion.idEstadoReservacion===2?
												  <span style={{"color": "#388E3C", "padding": "1px 1px 1px 1px","font-weight": "bold",  "font-size": "0.90em"}}>{reservacionMaster.estadoReservacion.estado}</span>
												   :reservacionMaster.estadoReservacion.idEstadoReservacion===4?
												   <span style={{"color": "#FF5500","padding": "1px 1px 1px 1px", "font-weight": "bold",  "font-size": "0.90em"}}>{reservacionMaster.estadoReservacion.estado}</span>
												   :
												    <span style={{"color": "#001111","padding": "1px 1px 1px 1px", "font-weight": "bold",  "font-size": "0.90em"}}>{reservacionMaster.estadoReservacion.estado}</span>
												  }</td>
                                             <td style={{ "text-align": "right"}}> {reservacionMaster.valor}</td>
											 <td>
                                                 <button onClick={ () => this.editReservacionMaster(reservacionMaster.idReservacionMaster,(reservacionMaster.cliente))} className="btn btn-info  btn-sm">Editar </button>
													 {/*<button style={{marginLeft: "10px"}} onClick={ () => this.deleteReservacion(reservacion.idReservacion)} className="btn btn-danger  btn-sm">Delete </button>*/}
													 {/*<button style={{marginLeft: "10px"}} onClick={ () => this.viewUsuario(usuario.id_usuario)} className="btn btn-info  btn-sm">View </button>*/}
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
					  }
						<br /><br /><br />

                 </div>
			     <br /><br /><br />
            </div>
			
        )
    }
}

export default ListReservacionMasterComponent
