import React, { Component } from "react";
import UsuarioService from '../services/UsuarioService';
import ListReservacionComponent from '../components/ListReservacionComponent'
//import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import logo from './LogoInicial.png'


export default class Login extends Component {
	constructor(props) {
        super(props)

        this.state = {
			email : '',
			password : ''
			
        }
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
		this.changePasswordHandler = this.changePasswordHandler.bind(this);
     
    }
	changeEmailHandler= (event) => {
        this.setState({email: event.target.value});
    }
	
	changePasswordHandler= (event) => {
        this.setState({password: event.target.value});
    }
	handleLoginUsuario = (e) => {
		//alert('aquivvvvv');
        e.preventDefault();
        let usuario = {
			email: this.state.email, 
			password: this.state.password,
			razon_social_usu:'',
			email_usu:'',
			id_usu : 0
	
			};
        console.log('usuario => ' + JSON.stringify(usuario));

        // step 5
        if(this.state.email && this.state.password){
			UsuarioService.getUsuarioByEmailAndPassword(this.state.email, this.state.password).then(res =>{
				  console.log('RES => ' + JSON.stringify(res.data[0]));
				if (res.data.length===1){
					sessionStorage.setItem('razon_social',res.data[0].razon_social);
					sessionStorage.setItem('email',res.data[0].email);
					sessionStorage.setItem('id_usuario',res.data[0].id_usuario);
					sessionStorage.setItem('id_tipo_usuario',res.data[0].id_tipo_usuario);
					console.log("USUARIO LOGUIN "+sessionStorage.getItem('id_usuario'))
					this.props.history.push('/reservacionesMaster');
					window.location.reload('false');
					
					
				}else{
					this.setState({"emai":""})
					this.setState({"password":""})
					
					alert('Error: Usuario o contraseña incorrecta ');
					
					window.location.reload(false);
					//this.props.history.push('/');
				}
                //
            });
			
			
			
        }else{
            alert('ERROR: Digite usuario y contraseña');
        }
    }
	
    render() {
        return (
		 
		 <div>
                <br></br>

                   <div className = "container">
                        <div className = "row">
                       <div className = "card col-md-6 offset-md-3 offset-md-3">
							<div align={"center"}>
							
							<img src={logo} alt={"logo"} width={"100px"}/> 
							<br />
							<p style={{"color": "#000000", "font-weight":"bold", "backgroundColor": "#ccff99"}}>Sindicato de trabajadores de almacenes Éxito 2024</p>
							</div>
                              
							 
                       <div className = "card-body">
            <form>
                <h3>Sign In</h3>

                <div className="form-group">
                    <label>Email address</label>
                    <input type="email" id='email' name='email' className="form-control" placeholder="Enter email" 
					value={this.state.email} onChange={this.changeEmailHandler}/>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" id='password' name='password' className="form-control" placeholder="Enter password"
					value={this.state.password} onChange={this.changePasswordHandler}/>					
                </div>

                <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                    </div>
                </div>

                <button className="btn btn-primary btn-block" onClick={this.handleLoginUsuario.bind(this)}>Submit</button>
                <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p>
            </form>
			</div>
			</div>
			</div>
			</div>
						
			</div>
        );
    }
}
