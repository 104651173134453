import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
//import AsyncSelect from 'react-async-select'
//import { stateOptions } from "./docs/data";
//import _ from "lodash";
import debounce from "debounce-promise";
import SelUsuarioContext from './SelUsuarioContext';


export default class SingleSelect extends Component {
  //const [count, setCount] = useState(0)
  constructor(props) {
    //--------------------------------

    //--------------------------------
    super(props);
    //console.log('mmmmsamsmmsms' + this.props.idCliente)

    
      this.state= {
        selectedUsuValue: null
        , optionsUsu: []
      }
     
      console.log('usuario identificacion ' + this.props.idCliente.identificacion)
   
     

    const wait = 1000; // milliseconds
    const loadOptionsUsu = inputValue => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = debounce(loadOptionsUsu, wait, {
      leading: true
    });
  }

  //-------------------------------




  //---------------------------------
  // handle input change event
  handleUsuInputChange = value => {
    //setValue(value);
    this.setState({ inputValue: value });
  };

  // handle selection
  handleUsuChange = value => {
    //setSelectedValue(value);
    //alert('Cambiando ++++' + JSON.stringify(value));
    console.log('reservacion => ' + JSON.stringify(value));
    this.setState({ selectedUsuValue: value, id_cliente: value })
    localStorage.setItem('id_usu', value.id_usuario)
    //console.log('ID REC ' + localStorage.getItem('id_rec') + '  ID USU ' + localStorage.getItem('id_usu'))
    

  }
  loadOptionsUsu = (inputValue) => {
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
    //return fetch(`http://localhost:8080/api/v1/usuarios/find/${this.state.inputValue}`).then(res => res.json());
    return fetch(`http://club.sintraexito.com:8080/api/v1/usuarios/find/${this.state.inputValue}`).then(res => res.json());
  };
  /*
  getAsyncOptions(inputValue) {
    console.log("in here");
    return new Promise((resolve, reject) => {
      const filtered = _.filter(stateOptions, o =>
        _.startsWith(_.toLower(o.label), _.toLower(inputValue))
      );
      resolve(filtered.slice(0, 3));
    });
  }
  */
  componentDidMount() {
    const uu = this.props.idCliente;
    const mm = this.props.idCliente.id_usuario;
    //alert ('LLEGA idCliente zxc '+(mm)+'--'+(mm!=="**"))
    if (mm!=="**") {
      this.setState ({
        selectedUsuValue: this.props.idCliente
        , optionsUsu: []
        , defaultUsu: (this.props.idCliente.identificacion + '-' + this.props.idCliente.razon_social)

      })
    }
    
  }

  render() {
    return (
      <div>
        <AsyncSelect
          cacheOptions
          defaultOptions
          defaultInputValue={this.state.defaultUsu}
          value={this.state.selectedUsuValue}
          getOptionLabel={e => e.identificacion + '-' + e.razon_social}
          getOptionValue={e => e.id_usuario}
          loadOptions={this.loadOptionsUsu}
          onInputChange={this.handleUsuInputChange}
          onChange={this.handleUsuChange}
        />
      </div>
    );
  }
}
