import axios from 'axios';

const ITEM_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/items";
//const ITEM_API_BASE_URL = "http://localhost:8080/api/v1/items";
class ItemService {

    getItems(){
        return axios.get(ITEM_API_BASE_URL);
    }

    createItem(Item){
        return axios.post(ITEM_API_BASE_URL, Item);
    }

    getItemById(ItemId){
        return axios.get(ITEM_API_BASE_URL + '/' + ItemId);
    }

    updateItem(Item, ItemId){
        return axios.put(ITEM_API_BASE_URL + '/' + ItemId, Item);
    }

    deleteItem(ItemId){
        return axios.delete(ITEM_API_BASE_URL + '/' + ItemId);
    }
}

export default new ItemService()
