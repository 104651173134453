import axios from 'axios';

const RESERVACION_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/reservaciones";
//const RESERVACION_API_BASE_URL = "http://localhost:8080/api/v1/reservaciones";
class ReservacionService {

    getReservaciones(){
        return axios.get(RESERVACION_API_BASE_URL);
    }
    getReservacionesXmaster(reservacionMasterId){
        return axios.get(RESERVACION_API_BASE_URL+'/master/'+reservacionMasterId);
    }
    createReservacion(reservacion){
        
        return axios.post(RESERVACION_API_BASE_URL, reservacion);
    }

    getReservacionById(reservacionId){
        return axios.get(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }

    updateReservacion(reservacion, reservacionId){
        return axios.put(RESERVACION_API_BASE_URL + '/' + reservacionId, reservacion);
    }
	updateEstadoReservacion( reservacionId, estadoId){
		//alert ('actualizando id '+reservacionId+' nuevo estado id '+estadoId )
        return axios.put(RESERVACION_API_BASE_URL + '/' + reservacionId+'/'+estadoId);
    }

    deleteReservacion(reservacionId){
        return axios.delete(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }
}

export default new ReservacionService()
