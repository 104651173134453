/* eslint-disable no-restricted-globals */
import React, { Component, useState, useRef } from 'react'
import ReservacionService from '../services/ReservacionService';
import ReservacionMasterService from '../services/ReservacionMasterService';
import UsuarioService from '../services/UsuarioService';
import RecursoService from '../services/RecursoService'
import ErrorAppService from '../services/ErrorAppService'
import SingleSelect from "./selectUsu";
import Select from 'react-select'
//import AsyncSelect from "react-select/lib/Async";
//import AsyncSelect from 'react-async-select'
//import SelectUsuarios from './SelectUsuarios'


//import _ from "lodash";

class CreateReservacionMasterComponent extends Component {

	constructor(props) {

		super(props)

		//--------------------

		this.ChildElement = React.createRef();
		//--------------------



		//alert ('EL ID LLEGA '+this.props.match.params.id)
		console.log('EL ID LLEGA ' + this.props.match.params.id)
		console.log('EL cliente lLEGA zzzz ' + JSON.stringify(this.props.match.params.cli))
		let clientex = JSON.parse(JSON.stringify(this.props.match.params.cli))
		let clientez 
		//alert ('clientex'+clientex+'------clientez'+clientez)
		let clie= clientex;
		this.state = {
			// step 2
			id: this.props.match.params.id,
			idReservacionDetail: '_add',
			idUsuarioCrea: sessionStorage.getItem('id_usuario'),
			idRecurso: undefined,
			id_recurso: 0,
			reservacionesXmaster: [],
			idCliente: (clientex),
			id_cliente: 0,
			fechaIni: '',
			fechaFin: '',
			observaciones: '',
			idEstadoReservacion: undefined,
			id_estado_reservacion: 1,
			valor: 0,
			observacion: '',
			inputValue: '',
			selectedValue: null,
			noLoadOk: true,
			usuarios: [], optionsUsuarios: [], recursos: [], optionsRecursos: []

		}
		this.changeIdRecursoHandler = this.changeIdRecursoHandler.bind(this);
		this.changeIdClienteHandler = this.changeIdClienteHandler.bind(this);
		this.changeFechaIniHandler = this.changeFechaIniHandler.bind(this);
		this.changeFechaFinHandler = this.changeFechaFinHandler.bind(this);
		this.changeValorHandler = this.changeValorHandler.bind(this);
		this.changeObservacionHandler = this.changeObservacionHandler.bind(this);
		this.traeReservacionesXmaster = this.traeReservacionesXmaster.bind(this);
		this.changeSelectUser = this.changeSelectUser.bind(this);
		this.findRecursoById = this.findRecursoById.bind(this);
		//this.changeIdEstadoReservacionHandler = this.changeIdEstadoReservacionHandler.bind(this);



	}

	// step 3
	componentDidMount() {

		UsuarioService.getUsuarios().then((res) => {
			this.setState({ usuarios: res.data });
			this.setState({ optionsUsuarios: this.renderListUsu(), noLoadOk: false });
		});
		RecursoService.getRecursos().then((res) => {
			this.setState({ recursos: res.data });

		});
		// step 4
		//alert ('cargando ID '+this.state.id)
		if (this.state.id === '_add') {
			//alert('es nuevo');
			//return
			this.setState({ value: 0, idUsuarioCrea: sessionStorage.getItem('id_usuario') });
			//console.log('Usuario crea ID '+sessionStorage.getItem('id_usuario'));

		} else {
			//alert('para actualizar ccv'+this.state.id);
			ReservacionMasterService.getReservacionMasterById(this.state.id).then((res) => {
				let reservacionMaster = res.data;
				//let 
				//localStorage.setItem("id_usu", reservacionMaster.cliente.id_usuario);
				this.setState({
					id: reservacionMaster.idReservacionMaster,
					idReservacion: reservacionMaster.idReservacion,
					reservacionMaster: reservacionMaster,
					//usuarioCrea:this.findUsuarioById(localStorage.getItem('id_usuario')),
					recurso: reservacionMaster.recurso,
					idCliente: reservacionMaster.cliente,
					//id_cliente: reservacionMaster.cliente.id_usuario,
					fechaIni: reservacionMaster.fechaIni,
					fechaFin: reservacionMaster.fechaFin,
					valor: reservacionMaster.valor,
					observaciones: reservacionMaster.observaciones,
					//reservacionesXmaster:this.getReservacionesXmaster(reservacionMaster.idReservacion),
					estadoReservacion: reservacionMaster.estadoReservacion,
					idReservacionMaster: reservacionMaster.idReservacionMaster
					//selectedRecValue : {value: reservacionMaster.idRecurso.idRecurso, label : reservacion.idRecurso.descripcionS+'-'+reservacion.idRecurso.descripcionL}

				});
				console.log('reservacion inicial=> ' + JSON.stringify(reservacionMaster));


				//console.log('reservacion seleccionada=> ' + JSON.stringify(this.state.selectedRecValue));
			});

			let idReservacionMaster = this.state.idReservacion;
			//alert('BUSCANDO PARAv' + this.state.id)
			ReservacionService.getReservacionesXmaster(this.state.id).then((res) => {
				let reservacionesMaster = res.data;
				this.setState({
					reservacionesXmaster: reservacionesMaster
				});
			});

		}





	}

	traeReservacionesXmaster(id_reservacion_master) {
		//let idReservacionMaster = this.state.idReservacion;
		//alert('TRAYENDO RESERVACIONES X MASTER para master id ' + this.state.id)
		//alert('TRAYENDO RESERVACIONES X MASTER para master id ' + id_reservacion_master)
		ReservacionService.getReservacionesXmaster(id_reservacion_master).then((res) => {
			if (res) {
				let reservacionesMaster = res.data;
				this.setState({
					reservacionesXmaster: reservacionesMaster
				});
			} else {
				this.setState({
					reservacionesXmaster: []
				});
			}
		});
	}
	quitaReservacion(e, reservacionId) {
		e.preventDefault();
		let res = confirm('Confirma eliminar la reservacion?');
		if (res == true) {
			console.log('Inicio Eliminanado ID ' + reservacionId)
			ReservacionService.deleteReservacion(reservacionId).then((res) => {
				let eliminaReservacion = res.data;
				this.traeReservacionesXmaster(this.state.reservacionMaster.idReservacionMaster)
				this.editReservacionMaster(this.state.reservacionMaster.idReservacionMaster, (this.state.idCliente))
				alert('Registro eliminado ID ' + reservacionId)
			});
		} else {
			alert('Eliminacion CANCELADA ...');
		}
	}
	//====================================================
	saveOrUpdateReservacion = (e) => {
		e.preventDefault();
		//console.log('id_recurso zz '+JSON.stringify(localStorage.getItem('id_rec')))

		//alert('En saveOrUpdateReservacion Estado reservacion master ' + JSON.stringify(this.state.estadoReservacion))
		console.log('EN CREACION RESERVACIONES ' + localStorage.getItem('id_usu'))

		
		//this.setState({ idReservacionDetail: '_add' });
		//if (!clie) {

		//clie = this.state.idCliente;
		//}
		//clie = this.state.idCliente;
		//let recu = this.findRecursoById(localStorage.getItem('id_rec'));
		//console.log('RECU ' + JSON.stringify(recu))
		//if (!recu) {
		let recu = this.state.idRecurso;
		//}

		let reservacion = {



			idCliente: this.state.idCliente,
			idRecurso: this.state.idRecurso,
			//console.log('Recurso '+JSON.stringify(localStorage.getItem('id_rec'))
			idUsuarioCrea: this.state.idUsuarioCrea,
			//idReservacion : this.state.idReservacion,
			fechaIni: this.state.fechaIni,
			fechaFin: this.state.fechaFin,
			valor: this.state.valor,
			observaciones: this.state.observaciones,
			reservacionMaster:this.state.reservacionMaster,

			idEstadoReservacion: { "idEstadoReservacion": 1, "codigo": "1", "estado": "Reservada" },

			observaciones: this.state.observaciones


		};
		//id_cliente: this.state.idCliente.id_usuario,
		//id_recurso : this.state.idRecurso.idRecurso,
		//id_estado_reservacion : this.state.id_estado_reservacion

		console.log('antes de validar reservacion xyzz111000=> ' + JSON.stringify(reservacion));

		// step 5

		//alert ('Statte ID:'+this.state.idReservacionDetail)
		if (this.state.idReservacionDetail === '_add') {

			/*
		   ReservacionService.createReservacion(reservacion).then(res =>{
				this.props.history.push('/reservaciones');
			});
			*/
			let errorApp
			//alert("qqqqq idRecurso acas " + JSON.stringify(reservacion.idRecurso))
			//--------------------------
			ErrorAppService.validaByIdRecursoAndFechaBetween(reservacion.idRecurso.idRecurso, reservacion.fechaIni, reservacion.fechaFin).then((res) => {
				//console.log('aki aki '+res.data+'')
				//alert('reserIdRecuroso' +reservacion.idRecurso.idRecurso)
				//alert( 'ini' +reservacion.fechaIni+'---FIN '+reservacion.fechaFin)
				errorApp = res.data
				Object.freeze(errorApp)
				//console.log('errorAPP '+errorApp.idErrorApp)
				if (parseInt(errorApp.idErrorApp) > 0) {
					alert("ERROR: " + errorApp.descripcionErrorApp)
				} else {
					console.log('CREANDO RESERVACION ' + JSON.stringify(reservacion))
					//alert ('CREANDO RESERVACION ' + JSON.stringify(reservacion))
					ReservacionService.createReservacion(reservacion).then(res => {
						//this.props.history.push('/reservaciones');
						//alert('idUsuarioCrea '+reservacion.idUsuarioCrea)
						let reservacionNew = res;
						//alert ('CREADA LA RESERVACION :'+JSON.stringify/(reservacionNew))
						this.traeReservacionesXmaster(this.state.reservacionMaster.idReservacionMaster)

						//this.editReservacionMaster(this.state.reservacionMaster.idReservacionMaster, (this.state.idCliente))
						//this.traeReservacionesXmaster()
						//location.reload();

					});
					//this.editReservacionMaster(this.state.reservacionMaster.idReservacionMaster,(this.state.cliente))
				}

			}
			)
		} else {

			ReservacionService.updateReservacion(reservacion, this.state.id_detail).then(res => {
				//this.props.history.push('/reservaciones');
				location.reload();
			});


		}
	}
	//====================================================
	editReservacionMaster(id, idCliente) {
		// alert('cccc');
		let cli = JSON.stringify(idCliente)
		//alert('llega cliente ' + cli)
		//alert('llega idresmaster ' + id)

		this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
	}
	saveOrUpdateReservacionMaster = (e) => {
		e.preventDefault();

		//console.log('USUARIO EN CREACION RESERVACIONES '+localStorage.getItem('id_usu'))
		let clie; //= this.findUsuarioById(localStorage.getItem('id_usu')); 
		let crea = this.findUsuarioById(sessionStorage.getItem('id_usuario'));
		let idResMaster;
		clie = this.onBlurFechaFinHandler()
		//clie = this.state.idCliente;

		this.setState({ cliente: clie });
		//alert('EL CLIE ' + clie)

		if (clie == null || clie == undefined) {
			alert('ERROR: DEBE seleccionar un cliente');
			return false;
		}
		if (this.state.fechaIni.trim() == '' || this.state.fechaFin.trim() == '') {
			alert('ERROR: DEBE asignar fechas Inicio y Fin');
			return false;
		}
		/*
		let recu=this.findRecursoById(localStorage.getItem('id_rec')); 
		console.log('RECU '+JSON.stringify(recu))
		if (!recu){
			clie=this.state.idRecurso;
		}
		*/
		//console.log('Usuario crea 22 ID '+sessionStorage.getItem('id_usuario'));
		let reservacionMaster = {



			cliente: clie,
			//idRecurso: recu, 
			//console.log('Recurso '+JSON.stringify(localStorage.getItem('id_rec'))
			usuarioCrea: crea,
			idReservacion: this.state.idReservacion,
			fechaIni: this.state.fechaIni,
			fechaFin: this.state.fechaFin,
			valor: this.state.valor,
			observaciones: this.state.observaciones,
			estadoReservacion: { "idEstadoReservacion": 1, "codigo": "1", "estado": "Reservada" },

			observaciones: this.state.observaciones


		};
		//id_cliente: this.state.idCliente.id_usuario,
		//id_recurso : this.state.idRecurso.idRecurso,
		//id_estado_reservacion : this.state.id_estado_reservacion
		console.log('usuario crea xxx=> ' + JSON.stringify(reservacionMaster.usuarioCrea));
		console.log('reservacion zz=> ' + JSON.stringify(reservacionMaster));

		// step 5
		//alert ('Statte ID:'+this.state.id)
		if (this.state.id === '_add') {

			/*
		   ReservacionService.createReservacion(reservacion).then(res =>{
				this.props.history.push('/reservaciones');
			});
			*/
			ReservacionMasterService.createReservacionMaster(reservacionMaster).then(res => {
				//this.props.history.push('/reservacionesMaster')
				//------------------ACA-------------------
				let reservacionMasterNew = res.data;
				//let 
				idResMaster = reservacionMasterNew.idReservacionMaster
				clie =  reservacionMasterNew.cliente
				this.setState({
					
					id: reservacionMasterNew.idReservacionMaster,
					idReservacion: reservacionMasterNew.idReservacion,
					//usuarioCrea:this.findUsuarioById(localStorage.getItem('id_usuario')),
					recurso: reservacionMasterNew.recurso,
					cliente: reservacionMasterNew.cliente,
					//id_cliente : reservacion.IdCliente.id_usuario,
					fechaIni: reservacionMasterNew.fechaIni,
					fechaFin: reservacionMasterNew.fechaFin,
					valor: reservacionMasterNew.valor,
					observaciones: reservacionMasterNew.observaciones,
					reservacionMaster: reservacionMasterNew,
					estadoReservacion: reservacionMasterNew.estadoReservacion

					//selectedRecValue : {value: reservacionMaster.idRecurso.idRecurso, label : reservacion.idRecurso.descripcionS+'-'+reservacion.idRecurso.descripcionL}

				});
				//alert('en CFREATE traer resxmaster-final detail ')
				//this.traeReservacionesXmaster()
				//traeReservacionesXmaster()
				console.log('***** reservacion MASTER New GUARDADA => ' + JSON.stringify(reservacionMasterNew));
				//this.setState({reservacionMaster : {idReservacionMaster : reservacionMasterNew.idReservacionMaster}})
				//alert('Registro almacenado -->' + this.state.id)	;		
				//-----------------******-----------------
				//location.reload();

				//*****************************************************
				let reservacionDetail = this.state.reservacionesXmaster
				reservacionDetail.forEach(function (reservacion, index) {
					//console.log(`${index} : ${comida}`);

					reservacion.idReservacion = null;
					reservacion.reservacionMaster = reservacionMasterNew;
					reservacion.idEstadoReservacion.idEstadoReservacion = 1;

					reservacion.fechaIni = reservacionMasterNew.fechaIni
					reservacion.fechaFin = reservacionMasterNew.fechaFin
					reservacion.observaciones = reservacionMasterNew.observaciones
					reservacion.valor = reservacionMasterNew.valor
					//alert('zzzReservacion Detailxxx -->' + JSON.stringify(reservacion))
					//-----------------------------------------------
					let errorApp
					console.log("ojo idRecurso acas " + JSON.stringify(reservacion.idRecurso))
					//--------------------------
					ErrorAppService.validaByIdRecursoAndFechaBetween(reservacion.idRecurso.idRecurso, reservacion.fechaIni, reservacion.fechaFin).then((res) => {
						//console.log('aki aki '+res.data+'')
						errorApp = res.data
						Object.freeze(errorApp)
						//console.log('errorAPP '+errorApp.idErrorApp)
						if (parseInt(errorApp.idErrorApp) > 0) {
							alert("ERROR: " + errorApp.descripcionErrorApp)
						} else {
							console.log('CREANDO RESERVACION ' + JSON.stringify(reservacion))
							ReservacionService.createReservacion(reservacion).then(res => {
								let reservacionNew = res;
								//alert('CVREANDA reservacionDetail '+JSON.stringify(res))
								//this.props.history.push('/reservaciones');
								//alert('Actualizada reserva bb'+JSON.stringify(res))
								//this.traeReservacionesXmaster()

								//---------------ojoojojojoj


							});

						}

					})


				})
				
				//alert('en CFREATE traer resxmaster-final despues de condicion ')
				/*
				ReservacionService.getReservacionesXmaster(this.state.id).then((res) => {
					let reservacionesMaster = res.data;
					this.setState({
						reservacionesXmaster: reservacionesMaster
					});
				});
				*/
				//-----------------------------------------------

				
				
				//this.traeReservacionesXmaster(reservacionMasterNew.idReservacionMaster)
				//****************************************************
			})



		} else {
			
			//alert('pppp ACTUALIZANDO ' + JSON.stringify(reservacionMaster) + 'qqqq CON ID ' + this.state.id)
			clie=reservacionMaster.cliente;
			ReservacionMasterService.updateReservacionMaster(reservacionMaster, this.state.id).then(res => {
				let resMaster = res;
				idResMaster = resMaster.idReservacionMaster
				//this.props.history.push('/reservacionesMaster');
				//location.reload();


			});
			//alert('en UPDATE traer resxmaster')
			//this.traeReservacionesXmaster(idResMaster)
		}

		//****** */
		//this.traeReservacionesXmaster(idResMaster)

		
		
		//alert('*******************antes*OJO RECARGANDO************************'+idResMaster)
		setTimeout(() => {
			//alert('********************OJO RECARGANDO************************'+idResMaster)
		ReservacionService.getReservacionesXmaster(idResMaster).then((res) => {
			//alert('********************OJO RECARGANDO*tryendo***********************'+idResMaster)
			let reservacionesMaster = res.data;
			this.setState({
				reservacionesXmaster: reservacionesMaster
			});
			//console.log("Delayed for 6 second.");
			
			//this.editReservacionMaster(idResMaster, clie)
			
		  })
		 
		}, 4000);
		 this.traeReservacionesXmaster(idResMaster)
		 this.editReservacionMaster(idResMaster, clie)
		  //location.reload()
		alert('La informacion se ha guardado correctamente ')
	}

	updateEstado2ReservacionHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ReservacionMasterService.updateEstadoReservacionMaster(this.state.id, 2).then(res => {
			this.props.history.push('/reservacionesMaster')
		});
	}
	updateEstado4ReservacionHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ReservacionMasterService.updateEstadoReservacionMaster(this.state.id, 4).then(res => {
			this.props.history.push('/reservacionesMaster')
		});
	}
	updateEstado44ReservacionHandler = (event) => {
		event.preventDefault()
		//alert('llamando xyz:'+this.state.id)

		//------------------------------
		//
		//------------------------------	
		let res = confirm('CONFIRMAR: Se anula la reservacion actual y se crea NUEVA para MODIFICAR?');
		if (res == true) {
			ReservacionMasterService.updateEstadoReservacionMaster(this.state.id, 4).then(res => {

				this.setState({ id: '_add' });

			});
		} else {
			alert('MODIFICACION cancelada')
		}
		//alert ('aca xyz'+this.state.id)
	}

	renderListUsu() {
		return (this.state.usuarios.map(data => ({ label: data.razon_social + '-' + data.identificacion, value: data.id_usuario })))
	}

	renderList() {
		return (this.state.recursos.map(data => ({ label: data.descripcionS + '-' + data.descripcionL, value: data.idRecurso })))
	}
	changeIdRecursoHandler = (event) => {
		//alert ('executa');
		this.setState({ id_recurso: event.target.value });
		this.setState({ idRecurso: undefined });
		//alert ('valor '+event.target.value)
		for (let i = 0; i < this.state.recursos.length; i++) {
			if (this.state.recursos[i].idRecurso === this.state.id_recurso) {
				this.setState({ idRecurso: this.state.recursos[i] });
				//alert('encontro');

			}
		}

		//console.log('recurso=> ' + JSON.stringify(idRecurso));

	}

	changeIdClienteHandler = (event) => {
		//alert('aca identifica '+ event.target.value);
		this.setState({ id_cliente: event.target.value });
	}

	changeFechaIniHandler = (event) => {
		this.setState({ fechaIni: event.target.value });
	}
	changeFechaFinHandler = (event) => {
		this.setState({ fechaFin: event.target.value });
	}
	changeValorHandler = (event) => {
		//alert('actualiza valor-->'+event.target.value)
		this.setState({ valor: event.target.value });
	}
	changeObservacionHandler = (event) => {
		this.setState({ observaciones: event.target.value });
	}
	changeIdEstadoReservacionHandler = (event) => {
		this.setState({ id_estado_reservacion: event.target.value });
	}



	//***************************************
	changeSelectUser() {
		alert('cambia select')
	}

	findRecursoById(id_rec) {
		//alert(' buscando id_recurso ' + id_rec + ' en ' + this.state.recursos.length);
		let recurso = undefined;
		for (let i = 0; i < this.state.recursos.length; i++) {
			let idrec = this.state.recursos[i].idRecurso;
			//console.log(i + '--'+idrec +' BUSCANDO '+ parseInt(id_rec))

			if (parseInt(this.state.recursos[i].idRecurso) == parseInt(id_rec)) {
				recurso = this.state.recursos[i];
				console.log('recurso enconrado ' + (recurso.nroHabitacion))
				return recurso;
			}
		}

		return recurso;
	}

	findUsuarioById(id_usu) {
		//alert ('buscando id_usuario '+id_usu+' en '+this.state.usuarios.length);
		let usuario = null;
		for (let i = 0; i < this.state.usuarios.length; i++) {
			let idu = parseInt(this.state.usuarios[i].id_usuario);
			//console.log(i+' buscando '+id_usu+' en '+parseInt(this.state.usuarios[i].id_usuario))
			if (parseInt(this.state.usuarios[i].id_usuario) == parseInt(id_usu)) {
				usuario = this.state.usuarios[i];
				return usuario;
			}
		}
		//alert ('usuario enconrado '+JSON.parse(usuario))
		return usuario;
	}

	getTitle() {
		if (this.state.id === '_add') {
			return <h3 className="text-center">Nueva reservacion</h3>
		} else {
			return <h3 className="text-center">Actualiza reservacion</h3>
		}
	}

	// handle input change event
	handleRecInputChange = value => {
		//setValue(value);
		this.setState({ inputRecValue: value });
	};

	cancel() {
		this.props.history.push('/reservacionesMaster');
	}

	// handle selection
	handleRecChange = value => {
		//setSelectedValue(value);
		//alert('Cambiando ++++'+ JSON.stringify(value));
		console.log('antes EL RECURSO ES => ' + JSON.stringify(value));
		this.setState({ selectedRecValue: value, id_recurso: value })
		let recurso = this.findRecursoById(value.value);
		console.log('despues EL RECURSO ES => ' + JSON.stringify(recurso));
		this.setState({ idRecurso: recurso })
		//localStorage.setItem('id_rec',value);
		//localStorage.setItem('id_rec', value.value)
		//console.log('En storage ojojojo' + JSON.stringify(localStorage.getItem('id_rec')))

	}

	//---------------------

	onBlurFechaIniHandler = () => {
		//**************OJO OJO ******************** */
		const childelement = this.ChildElement.current;
		//alert("P R U E B A  current state of child is :  "+ JSON.stringify(childelement.state.selectedUsuValue)); 
		//alert("P R U E B A  current state of child is :  "+ JSON.stringify(childelement.state)); 
		this.setState({ "idCliente": childelement.state.selectedUsuValue })
		return childelement.state.selectedUsuValue
		//if(clie){
		//
		//clie =childelement.state.selectedUsuValue
		//}
		//**************OJO OJO ******************** */
	}

	onBlurFechaFinHandler() {
		//**************OJO OJO ******************** */
		const childelement = this.ChildElement.current;
		//alert("P R U E B A  current state of child is :  " + JSON.stringify(childelement.state.selectedUsuValue));
		//alert("P R U E B A  current state of child is :  "+ JSON.stringify(childelement.state)); 
		this.setState({ "idCliente": childelement.state.selectedUsuValue })
		return childelement.state.selectedUsuValue
		//if(clie){
		//
		//clie =childelement.state.selectedUsuValue
		//}
		//**************OJO OJO ******************** */
	}
	//-------------------------
	render() {
		return (
			<div>
				<br></br>
				<div className="container">
					<div className="row">
						<div className="card col-md-6 ">
							{
								this.getTitle()
							}
							<div className="card-body">
								<form>
									<div className="form-group">

									</div>

									<div className="form-group">
										<label> Cliente: </label>

										<SingleSelect
											ref={this.ChildElement}
											idCliente={JSON.parse(this.props.match.params.cli)}
										/>

									</div>
									<div className="row">
										<div className="form-group col-md-6" >
											<label> <span style={{ "color": "green", "font-size": "0.775em" }}>Fecha entrada: </span> </label>
											<input placeholder="2021-12-23" required disabled={(this.state.id !== '_add')} type="date" name="fechaIni" className="form-control"
												value={this.state.fechaIni} onChange={this.changeFechaIniHandler} />
										</div>
										<div className="form-group col-md-6">
											<label> <span style={{ "color": "blue", "font-size": "0.775em" }}>Fecha salida: </span> </label>
											<input placeholder="2021-12-25" onBlur={this.onBlurFechaIniHandler} required disabled={(this.state.id !== '_add')} type="date" name="fechaFin" className="form-control"
												value={this.state.fechaFin} onChange={this.changeFechaFinHandler} />

										</div>
									</div>
									<div className="form-group">
										<label> Valor: </label>
										<input placeholder="150000" type="number" name="valor" className="form-control"
											value={this.state.valor} onChange={this.changeValorHandler} />

									</div>
									<div className="form-group">
										<label> Observacion: </label>
										<input placeholder="" name="observaciones" className="form-control"
											value={this.state.observaciones} onChange={this.changeObservacionHandler} />
									</div>

									<button className="btn btn-primary btn-sm" disabled={this.state.noLoadOk} name="new_reservacion" id="new_reservacion" onClick={this.saveOrUpdateReservacionMaster}>Guardar</button>

									<button className="btn btn-success btn-sm" disabled={(this.state.id === '_add')} onClick={this.updateEstado2ReservacionHandler} style={{ marginLeft: "10px" }}>Confirmar</button>
									<button className="btn btn-danger btn-sm" disabled={(this.state.id === '_add')} onClick={this.updateEstado4ReservacionHandler} style={{ marginLeft: "10px" }}>Anular</button>
									{/*
											//raimot mayo 13 de 2024 no se puede implementar opcion de edicion completa por las fechas
											*/}
									<button className="btn btn-info btn-sm" disabled={(this.state.id === '_add')} onClick={this.updateEstado44ReservacionHandler} style={{ marginLeft: "10px" }}>Modificar</button>

									<button className="btn btn-secondary btn-sm" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Salir</button>
								</form>
								<br /><br /> <br />
							</div>
						</div>

						<div className="card col-md-6 ">
							<h3 className="text-center">Habitacion(es)</h3>
							<div className="card-body">
								<form>
									<div className="form-group">
										<label> Habitacion: </label>
										<Select
											defaultOptions
											value={this.state.selectedRecValue}
											options={this.renderList()}
											onInputChange={this.handleRecInputChange}
											onChange={this.handleRecChange}
											isDisabled={(false)}
										/>
									</div>

									<button className="btn  btn-warning  btn-sm" disabled={this.state.noLoadOk} name="new_reservacion" id="new_reservacion" onClick={this.saveOrUpdateReservacion}>Agregar</button>


									<br />

								</form>
								<br />
								<br />

								<table style={{ fontSize: 12 }} class="table table-sm">


									<thead>
										<tr>
											<th width='10%' scope="col">Nro.</th>
											<th width='85%' scope="col">Descripcion</th>
											<th width='5%' scope="col"></th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.reservacionesXmaster.map(
												reservacionx =>
													<tr key={reservacionx.idRecurso.idRecurso}>
														<td> {reservacionx.idRecurso.descripcionS} </td>
														<td> {reservacionx.idRecurso.descripcionL} </td>
														<td>
															<a href="#!">
																<span style={{ color: "red" }} class="fas fa-times" onClick={e => this.quitaReservacion(e, reservacionx.idReservacion)}></span>
															</a>
														</td>
													</tr>
											)
										}
									</tbody>
								</table>

								<br /><br /> <br />
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}

export default CreateReservacionMasterComponent
