import React, { Component, useState } from 'react'
import ReservacionService from '../services/ReservacionService';
import UsuarioService from '../services/UsuarioService';
import RecursoService from '../services/RecursoService'
import ErrorAppService from '../services/ErrorAppService'
import ContratoService from '../services/ContratoService'
import ContratoDetalleService from '../services/ContratoDetalleService'
import ItemService from '../services/ItemService'
import SingleSelect from "./selectUsu";
//import Select from 'react-select'
//import AsyncSelect from "react-select/lib/Async";
//import AsyncSelect from 'react-async-select'
//import SelectUsuarios from './SelectUsuarios'
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

import Select from 'react-select'


import jQuery from 'jquery'

import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
//-----
//import _ from "lodash";

class CreateReservacionComponent extends Component {
    constructor(props) {
        super(props)
		
		console.log ('EL ID LLEGA '+this.props.match.params.id)
		
        this.state = {
            // step 2
            id: this.props.match.params.id,
			isOpen: false,
            idCliente: this.props.match.params.cli,
			id_cliente : 0,
			idUsuarioCrea : '',
            fecha: '',
			contacto : '',
            observaciones1: '',
			observaciones2: '',
			idEstadoContrato: undefined,
			id_estado_contrato : 0,
			contratoDetalles : [],
            vltTotal: 0,
			contratoDetalle : undefined,
			noLoadOk : true,
			selectedCliValue : undefined,
			optionsUsuarios: [],
			itemObj : undefined,
			id_item : 0,
			edit_id_contrato_detalle : 0,
			items: [], optionsItems: []

        }
      
		//this.changeIdClienteHandler = this.changeIdClienteHandler.bind(this);
        this.changeFechaHandler = this.changeFechaHandler.bind(this);
		 /*
		this.changeVlrTotalHandler = this.changeVlrTotlHandler.bind(this);
		this.changeObservacion1Handler = this.changeObservacion1Handler.bind(this);
		this.changeObservacion2Handler = this.changeObservacion2Handler.bind(this);
		*/
		//this.changeIdEstadoReservacionHandler = this.changeIdEstadoReservacionHandler.bind(this);
		
		

    }
	openModal = () => this.setState({ isOpen: true });
	closeModal = () => this.setState({ isOpen: false });
	verContratoDetalle(edit_id_contrato_detalle){
		console.log ('aca zzzz '+edit_id_contrato_detalle)
		this.setState({edit_id_contrato_detalle : edit_id_contrato_detalle})
		//---------------------------------------------------------------
		 if(edit_id_contrato_detalle === '_add'){
			//alert('es nuevo');
			this.setState({
			detDescripcion:'',
			detIdItem:{},
			detIdContrato : this.state.id,
			detCantidad: 0,
			detVlrUnitario : 0,
			detSubTotal : 0,
			id_item : 0,
			isOpen: true
			 });
			
        }else{
			//alert('para actualizar xx'+this.state.id);
            ContratoDetalleService.getContratoDetalleById(edit_id_contrato_detalle).then( (res) =>{
                let contratoDetalleObj = res.data;
				console.log()
                this.setState({
					detDescripcion: contratoDetalleObj.descripcion,
					detIdItem:contratoDetalleObj.idItem,
					detIdContrato : contratoDetalleObj.idContrato,
					detCantidad: contratoDetalleObj.cantidad,
					detVlrUnitario : contratoDetalleObj.vlrUnitario,
					detSubTotal : contratoDetalleObj.subTotal,
					id_item : contratoDetalleObj.idItem.idItem,
					itemObj: contratoDetalleObj.idItem,
					isOpen: true
	
                })
				
            })
			 
		
		//-------------------------------------------------------------
		
		
			ContratoDetalleService.getContratoDetalleById(edit_id_contrato_detalle).then((res) => {
				this.setState({ contratoDetalle: res.data})
				this.setState({id_item : res.data.idItem.idItem}) 
				
				this.setState({isOpen: true})
				/*
				UsuarioService.getUsuarioById(this.state.reservacion.idUsuarioCrea).then((res) =>{
					this.setState({usuarioCrea: res.data})
					console.log('usuRes '+JSON.stringify(this.state.usuarioCrea))
				});
				*/
				
			});
		//this.setState({isOpen: true})
		}
	}
    // step 3
    componentDidMount(){

	
		UsuarioService.getUsuarios().then((res)=> {
			this.setState({ usuarios: res.data});
			
			this.setState({optionsUsuarios: this.renderListUsu(), noLoadOk: false});
		});
        // step 4
		//alert ('cargando ID '+this.state.id)
        if(this.state.id === '_add'){
			//alert('es nuevo');
			UsuarioService.getUsuarioById(sessionStorage.getItem('id_usuario')).then((res)=> {
				this.setState({ idUsuarioCrea: res.data});
			
			});
			//this.setState({value : 0, idUsuarioCrea: sessionStorage.getItem('id_usuario') });
        }else{
			//alert('para actualizar xx'+this.state.id);
            ContratoService.getContratoById(this.state.id).then( (res) =>{
                let contrato = res.data;
				//let 
				Object.freeze(contrato)
				localStorage.setItem('id_usu',contrato.idCliente.id_usuario)
				
                this.setState({
					idContrato:contrato.idContrato,
					idUsuarioCrea:contrato.idUsuarioCrea,
					idCliente: contrato.idCliente,
					contacto : contrato.contacto,
					fecha : contrato.fecha,
					vlrTotal : contrato.vlrTotal,
					observaciones1 : contrato.observaciones1,
					observaciones2 : contrato.observaciones2,
					idEstadoContrato : contrato.idEstadoContrato,
					contratoDetalles : contrato.contratoDetalles,
				
					selectedCliValue : {value: contrato.idCliente.idCliente, label : contrato.idCliente.identificacion+'-'+contrato.idCliente.razon_social}
					
					
                });
				console.log('contrato inicial=> ' + JSON.stringify(contrato));
				console.log('cliente seleccionada=> ' + JSON.stringify(this.state.selectedCliValue));
            });
			 
			
        }  
		ItemService.getItems().then((res)=> {
			this.setState({ items: res.data});
		});

		
    }
	
    saveOrUpdateContrato = (e) => {
        e.preventDefault();
		//console.log('id_recurso zz '+JSON.stringify(localStorage.getItem('id_rec')))
		//let ire=localStorage.getItem('id_rec');
		//alert('busca recurso id '+ire);
		console.log('id_usuario zzz '+JSON.stringify(localStorage.getItem('id_usu')))
		let clie = this.state.idCliente;
		if(localStorage.getItem('id_usu') !== null){
			
			
			clie = this.findUsuarioById(localStorage.getItem('id_usu'))
		}
		let id_contra = this.state.id
		if(this.state.id === '_add'){
			id_contra = 0
		}
		console.log('ID CLIENTE SAVE hhhh'+JSON.stringify(clie))
		
		//let clie = this.findUsuarioById(localStorage.getItem('id_usu')); 
		let contrato = {
				 
			//console.log('Recurso '+JSON.stringify(localStorage.getItem('id_rec'))
			idContrato : id_contra,
			idCliente : clie,
			fecha : this.state.fecha,
			contacto : this.state.contacto,
			vlrTotal : this.state.vlrTotal,
			observaciones1: this.state.observaciones1,
			observaciones2: this.state.observaciones2,
			idEstadoContrato:{"idEstadoContrato":1,"codigo":"1","estado":"Creado"},
			
			};
			//id_cliente: this.state.idCliente.id_usuario,
			//id_recurso : this.state.idRecurso.idRecurso,
			//id_estado_reservacion : this.state.id_estado_reservacion

			console.log('contrato zzzz=> ' + JSON.stringify(contrato));

        // step 5
        if(this.state.id === '_add'){
           
			
		   ContratoService.createContrato(contrato).then(res =>{
				let contra = res.data
				this.setState({id : contra.idContrato})
				if(contra.idContrato !== 0){
					alert('OK : Registro grabado')
				}else{
					alert('Error : grabando')
				}
                this.props.history.push('/add-contrato/'+contra.idContrato+'/'+JSON.stringify(contra.idCliente));
            });
			
			
        }else{
           
			ContratoService.updateContrato(contrato, this.state.id).then( res => {
                let contra = res.data
				if(contra.idContrato !== 0){
					alert('OK : Registro grabado')
				}else{
					alert('Error : grabando')
				}
                this.props.history.push('/add-contrato/'+contra.idContrato+'/'+JSON.stringify(contra.idCliente));
            });
			
			
        }
    }
	deleteContratoDetalle(id){
        ContratoDetalleService.deleteContratoDetalle(id).then( res => {
            this.setState({isOpen: false})
				ContratoService.getContratoById(this.state.id).then( (res) =>{
                let contrato = res.data;
				//let 
				if(contrato.idContrato !== 0){
					alert('OK : Registro eliminado')
				}else{
					alert('Error : eliminando')
				}
                this.setState({
					idContrato:contrato.idContrato,
					idUsuarioCrea:contrato.idUsuarioCrea,
					idCliente: contrato.idCliente,
					contacto : contrato.contacto,
					fecha : contrato.fecha,
					vlrTotal : contrato.vlrTotal,
					observaciones1 : contrato.observaciones1,
					observaciones2 : contrato.observaciones2,
					idEstadoContrato : contrato.idEstadoContrato,
					contratoDetalles : contrato.contratoDetalles,
				
					selectedCliValue : {value: contrato.idCliente.idCliente, label : contrato.idCliente.identificacion+'-'+contrato.idCliente.razon_social}
					
					
                });
				console.log('contrato inicial=> ' + JSON.stringify(contrato));
				console.log('cliente seleccionada=> ' + JSON.stringify(this.state.selectedCliValue));
			});
        });
    }
   saveOrUpdateContratoDetalle = (e) => {
        e.preventDefault();
		//console.log('id_recurso zz '+JSON.stringify(localStorage.getItem('id_rec')))
		//let ire=localStorage.getItem('id_rec');
		//alert('busca recurso id '+ire);
		console.log('id_usuario zzz '+JSON.stringify(localStorage.getItem('id_usu')))
		
		
		//let clie = this.findUsuarioById(localStorage.getItem('id_usu')); 
		/*
		detDescripcion: contratoDetalleObj.descripcion,
					detIdItem:contratoDetalleObj.idItem,
					detIdContrato : contratoDetalleObj.idContrato,
					detCantidad: contratoDetalleObj.cantidad,
					detVlrUnitario : contratoDetalleObj.vlrUnitario,
					detSubTotal : contratoDetalleObj.subTotal,
					id_item : contratoDetalleObj.idItem.idItem
		
		*/
		let id_contrato_det = this.state.edit_id_contrato_detalle;
		 if(this.state.edit_id_contrato_detalle === '_add'){
			id_contrato_det = 0
		 }
		let contratoDetalleEdit = {
				 
			//console.log('Recurso '+JSON.stringify(localStorage.getItem('id_rec'))
			idContrato : this.state.id,
			idContratoDetalle : id_contrato_det,
			descripcion : this.state.detDescripcion,
			cantidad : this.state.detCantidad,
			vlrUnitario : this.state.detVlrUnitario,
			subTotal: this.state.detSubTotal,
			idItem : this.state.itemObj
			
			};
			//id_cliente: this.state.idCliente.id_usuario,
			//id_recurso : this.state.idRecurso.idRecurso,
			//id_estado_reservacion : this.state.id_estado_reservacion

			console.log('contratoDetalleEdit yyyyzzz=> ' + JSON.stringify(contratoDetalleEdit));

		
        // step 5
        if(this.state.edit_id_contrato_detalle === '_add'){
           
			
		   ContratoDetalleService.createContratoDetalle(contratoDetalleEdit).then(res =>{
			   this.setState({isOpen: false})
			    ContratoService.getContratoById(this.state.id).then( (res) =>{
                let contrato = res.data;
				if(contrato.idContrato !== 0){
					alert('OK : Registro grabado')
				}else{
					alert('Error : grabando')
				} 
                this.setState({
					idContrato:contrato.idContrato,
					idUsuarioCrea:contrato.idUsuarioCrea,
					idCliente: contrato.idCliente,
					contacto : contrato.contacto,
					fecha : contrato.fecha,
					vlrTotal : contrato.vlrTotal,
					observaciones1 : contrato.observaciones1,
					observaciones2 : contrato.observaciones2,
					idEstadoContrato : contrato.idEstadoContrato,
					contratoDetalles : contrato.contratoDetalles,
				
					selectedCliValue : {value: contrato.idCliente.idCliente, label : contrato.idCliente.identificacion+'-'+contrato.idCliente.razon_social}
					
					
                });
				console.log('contrato inicial=> ' + JSON.stringify(contrato));
				console.log('cliente seleccionada=> ' + JSON.stringify(this.state.selectedCliValue));
            });
                //this.props.history.push(`/add-contrato/${this.props.match.params.id}/${this.props.match.params.cli}`);
				
            });
			
			
        }else{
           
			ContratoDetalleService.updateContratoDetalle(contratoDetalleEdit, this.state.edit_id_contrato_detalle).then( res => {
				this.setState({isOpen: false})
				 ContratoService.getContratoById(this.state.id).then( (res) =>{
                let contrato = res.data;
				if(contrato.idContrato !== 0){
					alert('OK : Registro grabado')
				}else{
					alert('Error : grabando')
				} 
                this.setState({
					idContrato:contrato.idContrato,
					idUsuarioCrea:contrato.idUsuarioCrea,
					idCliente: contrato.idCliente,
					contacto : contrato.contacto,
					fecha : contrato.fecha,
					vlrTotal : contrato.vlrTotal,
					observaciones1 : contrato.observaciones1,
					observaciones2 : contrato.observaciones2,
					idEstadoContrato : contrato.idEstadoContrato,
					contratoDetalles : contrato.contratoDetalles,
				
					selectedCliValue : {value: contrato.idCliente.idCliente, label : contrato.idCliente.identificacion+'-'+contrato.idCliente.razon_social}
					
					
                });
				console.log('contrato inicial=> ' + JSON.stringify(contrato));
				console.log('cliente seleccionada=> ' + JSON.stringify(this.state.selectedCliValue));
            });
                //this.props.history.push(`/add-contrato/${this.props.match.params.id}/${this.props.match.params.cli}`);
            });
			
			
        }
    }
	updateEstado2ContratoHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ContratoService.updateEstadoContrato(this.state.id, 2).then( res => {
				let contra = res.data
				if(contra.idContrato !== 0){
					alert('OK : Registro confirmado')
				}else{
					alert('Error : grabando')
				} 
                this.props.history.push('/add-contrato/'+contra.idContrato+'/'+JSON.stringify(contra.idCliente));
		});
	}
	updateEstado4ContratoHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ContratoService.updateEstadoContrato(this.state.id, 4).then( res => {
				let contra = res.data
				if(contra.idContrato !== 0){
					alert('OK : Registro anulado')
				}else{
					alert('Error : grabando')
				} 
                this.props.history.push('/add-contrato/'+contra.idContrato+'/'+JSON.stringify(contra.idCliente));
		});
	}
	
	renderListUsu() {
			return (this.state.usuarios.map(data =>({label: data.razon_social+'-'+data.identificacion, value : data.id_usuario})))
	}
    changeIdRecursoHandler= (event) => {
		alert ('executa');
        this.setState({id_recurso: event.target.value});
		this.setState({idRecurso : undefined});
		alert ('valor '+event.target.value)
		for (let i=0;i<this.state.recursos.length;i++){
			if(this.state.recursos[i].idRecurso===this.state.id_recurso){
				this.setState({idRecurso : this.state.recursos[i]});
				alert('encontro');
				
			}
		}
		
        //console.log('recurso=> ' + JSON.stringify(idRecurso));
		
    }

    changeIdClienteHandler= (event) => {
		//alert('aca identifica '+ event.target.value);
        this.setState({id_cliente: event.target.value});
		UsuarioService.getUsuarioById(this.state.id_cliente).then((res)=> {
			this.setState({ idCliente: res.data});
			
			
		});
		
		
    }

    changeFechaHandler= (event) => {
		//alert('cambia fecha '+event.target.value)
        this.setState({fecha: event.target.value});
    }
   
	changeVlrTotalHandler= (event) => {
		//alert('actualiza valor-->'+event.target.value)
        this.setState({vlrTotal: event.target.value});
    }
	changeObservaciones1Handler= (event) => {
        this.setState({observaciones1: event.target.value});
    }
	changeObservaciones2Handler= (event) => {
        this.setState({observaciones2: event.target.value});
    }
	changeContactoHandler= (event) => {
        this.setState({contacto: event.target.value});
    }



	
	findUsuarioById(id_usu){
		//alert ('buscando id_usuario '+id_usu+' en '+this.state.usuarios.length);
		let usuario = null;
		for (let i=0;i<this.state.usuarios.length;i++){
			let idu=parseInt(this.state.usuarios[i].id_usuario);
			//console.log(i+' buscando '+id_usu+' en '+parseInt(this.state.usuarios[i].id_usuario))
			if (parseInt(this.state.usuarios[i].id_usuario) == parseInt(id_usu)){
				usuario = this.state.usuarios[i];
				return usuario;
			}
		}
		//alert ('usuario enconrado '+JSON.parse(usuario))
		return usuario;
	}
	
	async findUsuarioByIds(id_usu){
		let usuario = this.state.idCliente;
		 UsuarioService.getUsuarioById(id_usu).then((res)=> {
			this.setState({ idCliente: res.data});
			console.log('ID CLIENTE SAVE cccc '+JSON.stringify(this.state.idCliente))
			usuario = this.state.idCliente
			
		});
		return usuario
	}

    getTitle(){
        if(this.state.id === '_add'){
            return <h3 className="text-center">Nuevo contrato</h3>
        }else{
            return <h3 className="text-center">Actualiza contrato</h3>
        }
    }

	// handle input change event
	handleRecInputChange = value => {
		//setValue(value);
		this.setState({inputRecValue : value});
		
	};

	cancel(){
        this.props.history.push('/contratos');
    }
	renderListItems() {
		console.log('items render '+JSON.stringify(this.state.items))
		return (this.state.items.map(data =>({label:data.descripcionS, value:data.idItem})))
	}
	defaultSelectId_item(id_item){
		//alert ('xxxxid_item '+id_item)
		let opcionesx=this.renderListItems();
		console.log('opcionesITEMS '+JSON.stringify())
		for (let i=0; i< opcionesx.length; i++){
			if(opcionesx[i].value===id_item){
				
				console.log('item '+opcionesx[i].label);
				return opcionesx[i]
			}
		}
		return {value:0,label:'Sel opcion....'};
	}
	handleChangeSelItem(selectedOption) {
		
		console.log('xxxx lbl '+selectedOption.label + ' value '+selectedOption.value )
		let val=selectedOption.value;
		let lbl=selectedOption.label;
		this.setState({id_item : val });
		
		ItemService.getItemById(val).then((res)=> {
				this.setState({ itemObj: res.data});
				
			
		});
		//console.log('yyyy '+this.state.selOption_label)
	}
	

	changeDetDescripcionHandler= (event) => {
		
        this.setState({detDescripcion: event.target.value});
    }
	changeDetCantidadHandler = (event) => {
		
		this.setState({detCantidad : event.target.value});
		
	};
	changeDetVlrUnitarioHandler = (event) => {
		
		this.setState({detVlrUnitario : event.target.value});
		
	};
	calculaSubTotal = () => {
		
		this.setState({detSubTotal : this.state.detCantidad * this.state.detVlrUnitario});
		
	};
  // handle selection
   handleRecChange = value => {
    //setSelectedValue(value);
	//alert('Cambiando ++++'+ JSON.stringify(value));
	 console.log('EL RECURSO ES => ' + JSON.stringify(value));
	this.setState({selectedRecValue:value, id_recurso:value})
	//localStorage.setItem('id_rec',value);
	localStorage.setItem('id_rec',value.value)
	console.log('En storage ojojojo'+JSON.stringify(localStorage.getItem('id_rec')))
	
  }
    render() {
        return (
		
            <div>
                <br></br>

                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-10 offset-md-1 offset-md-1">
                                {
                                    this.getTitle()
                                }
								
								<Modal show={this.state.isOpen} onHide={this.closeModal}>
									<Modal.Header closeButton>
									  <Modal.Title>
									  Contrato Nro: {this.state.contratoDetalle===undefined?0:this.state.contratoDetalle.idContratoDetalle} 
									  
									</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<div className = "form-group">
                                            <label> Item: </label>
											<Select 
											value={this.defaultSelectId_item(this.state.id_item)}
											options={this.renderListItems()} 
											onChange={this.handleChangeSelItem.bind(this)}
											/>
                                        </div>
										<div className = "form-group">
												<label> Descripcion: </label>
												<input placeholder="" type="text" name="descripcion" className="form-control" 
													value={this.state.detDescripcion} onChange={this.changeDetDescripcionHandler}/>
													
										</div>
										<div className = "form-group">
												<label> Cantidad: </label>
												<input placeholder="99" id="cantidad" type="number" name="cantidad" className="form-control" 
													value={this.state.detCantidad} onChange={this.changeDetCantidadHandler} onBlur={this.calculaSubTotal}/>
										</div>
										<div className = "form-group">
												<label> Vlr. Unitario: </label>
												<input placeholder="99999" id="vlrUnitario" type="number" name="vlrUnitario" className="form-control" 
													value={this.state.detVlrUnitario} onChange={this.changeDetVlrUnitarioHandler} onBlur={this.calculaSubTotal}/>
										</div>
										<div className = "form-group">
												<label> Sub total: </label>
												<input placeholder="0" readOnly={true} id="subTotal" type="number" name="subTotal" className="form-control" 
													value={this.state.detSubTotal} readonly />
										</div>
									</Modal.Body>
									<Modal.Footer>
										
										<Button variant="primary sm" onClick={this.saveOrUpdateContratoDetalle}>Guardar</Button>
									</Modal.Footer>
							   
								</Modal>
								
								
                                <div className = "card-body">
                                    <form>
  
										<div className = "row">
											<div className = "form-group col-md-8" >
												<label > Cliente: </label>
												
												<SingleSelect idCliente = {JSON.parse(this.props.match.params.cli)} />
												 
											</div>
											
											<div className = "form-group col-md-4">
											<label> Fecha <span style={{"color": "green",  "font-size": "0.775em"}}>(mm/dd/yyyy)</span>: </label>
												  <input placeholder="2021-12-23"  type="date" name="fecha" className="form-control" 
													value={this.state.fecha} onChange={this.changeFechaHandler}/>
											</div>
										</div>
										<div className = "row">
											<div className = "form-group col-md-8">
												<label> Persona contacto: </label>
												<input  type="text" name="contacto" className="form-control" 
													value={this.state.contacto} onChange={this.changeContactoHandler}/>
													
											</div>
											<div className = "form-group col-md-4">
												<label> Valor totaly: </label>
												<input placeholder="0" type="text" name="vlrTotal" className="form-control" 
													value={this.state.vlrTotal} readOnly={true} onChange={this.changeVlrTotalHandler}/>
													
											</div>

										</div>
										
											<div className = "row">
												<div className="col-md-2">
													 Observacion1: 
												</div>
												<div className="col-md-10">
													<input placeholder="" name="observaciones1" className="form-control" 
														value={this.state.observaciones1} onChange={this.changeObservaciones1Handler}/>
												</div>
											</div>
										
										<br />
                                        <button className="btn btn-primary" disabled={this.state.noLoadOk} name="new_reservacion" id="new_reservacion" onClick={this.saveOrUpdateContrato}>Guardar</button>
										
										<button className="btn btn-success " disabled={(this.state.id === '_add')} onClick={this.updateEstado2ContratoHandler} style={{marginLeft: "10px"}}>Confirmar</button>
										<button className="btn btn-danger "  disabled={(this.state.id === '_add')} onClick={this.updateEstado4ContratoHandler} style={{marginLeft: "10px"}}>Anular</button>
										<button className="btn btn-secondary" onClick={this.cancel.bind(this)}  style={{marginLeft: "10px"}}>Salir</button>
										
										<a href={'http://54.37.17.157/sindi/contrato.php?id_contrato='+(this.state.id)} target={'_blank'} className="btn btn-primary" style={{marginLeft: "10px"}}>Print</a>
								   </form>
							
									
									   <div className = "row">
									   <hr />
                       <button onClick={ () => this.verContratoDetalle('_add')} className="btn btn-info  btn-sm">Add Item </button>
					  
					  {
						
						  
						<table id="example" className="table table-striped table-bordered dt-responsive nowrap display compact" style={{ width:100 +'%', "font-size": "0.90em" }}>
		  

                            <thead>
                                <tr>
									<th> opciones</th>
									<th> Cod </th>
                                   <th> Producto</th>
								    <th> Descripcion</th>
                                    <th> Cantidad</th>
                                 	<th> Vlr. Unit</th>
									<th> Subtotal</th>
									
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contratoDetalles.map(
                                        detalle => 
                                        <tr key = {detalle.idContratoDetalle}>
										 <td>
                                                 <button onClick={ () => this.verContratoDetalle(detalle.idContratoDetalle)} className="btn btn-info  btn-sm">Editar </button>
												<button onClick={ () => this.deleteContratoDetalle(detalle.idContratoDetalle)} className="btn btn-danger btn-sm">Borrar </button>
												{/*<button style={{marginLeft: "10px"}} onClick={ () => this.deleteReservacion(reservacion.idReservacion)} className="btn btn-danger  btn-sm">Delete </button>*/}
													 {/*<button style={{marginLeft: "10px"}} onClick={ () => this.viewUsuario(usuario.id_usuario)} className="btn btn-info  btn-sm">View </button>*/}
                                             </td>
											<td> {detalle.idItem.alias} </td> 
											 <td> {detalle.idItem.descripcionS} </td> 
											
											 <td> {detalle.descripcion} </td>  
                                             <td style={{ "text-align": "right"}}> {detalle.cantidad} </td> 
											 <td style={{ "text-align": "right"}}> {detalle.vlrUnitario} </td> 
											 <td style={{ "text-align": "right"}}> {detalle.subTotal}</td>
											
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
					  }
						<br />

                 </div>
									<br />
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateReservacionComponent
