import axios from 'axios';

const RESERVACION_MASTER_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/reservacionesMaster";
//const RESERVACION_MASTER_API_BASE_URL = "http://localhost:8080/api/v1/reservacionesMaster";
class ReservacionMasterService {

    getReservacionesMaster(){
        return axios.get(RESERVACION_MASTER_API_BASE_URL);
    }

    createReservacionMaster(reservacionMaster){
        return axios.post(RESERVACION_MASTER_API_BASE_URL, reservacionMaster);
    }

    getReservacionMasterById(reservacionMasterId){
        return axios.get(RESERVACION_MASTER_API_BASE_URL + '/' + reservacionMasterId);
    }

    updateReservacionMaster(reservacionMaster, reservacionMasterId){
        return axios.put(RESERVACION_MASTER_API_BASE_URL + '/' + reservacionMasterId, reservacionMaster);
    }
	updateEstadoReservacionMaster( reservacionMasterId, estadoId){
		//alert ('actualizando id '+reservacionId+' nuevo estado id '+estadoId )
        return axios.put(RESERVACION_MASTER_API_BASE_URL + '/' + reservacionMasterId+'/'+estadoId);
    }

    deleteReservacionMaster(reservacionMasterId){
        return axios.delete(RESERVACION_MASTER_API_BASE_URL + '/' + reservacionMasterId);
    }
}

export default new ReservacionMasterService()
