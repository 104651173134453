import axios from 'axios';

const RESERVACION_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/recursosReservacionesJSON";
//const RESERVACION_API_BASE_URL = "http://localhost:8080/api/v1/recursosReservacionesJSON";
class RecursosReservacionesJSONService {

    getRecursosReservaciones(){
        return axios.get(RESERVACION_API_BASE_URL);
    }
	
	getRecursosReservacionesIniFin(fechaIni,fechaFin){
        return axios.get(RESERVACION_API_BASE_URL + '/' + fechaIni + '/' + fechaFin);
    }
	
	getRecursosReservacionesPeriodo(periodo){
		//alert ('el periodo'+periodo)
        return axios.get(RESERVACION_API_BASE_URL + '/' + periodo);
    }
/*
    createReservacion(reservacion){
        return axios.post(RESERVACION_API_BASE_URL, reservacion);
    }

    getReservacionById(reservacionId){
        return axios.get(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }

    updateReservacion(reservacion, reservacionId){
        return axios.put(RESERVACION_API_BASE_URL + '/' + reservacionId, reservacion);
    }

    deleteReservacion(reservacionId){
        return axios.delete(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }
	*/
}

export default new RecursosReservacionesJSONService()
