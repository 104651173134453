import React, { Component } from 'react'
import UsuarioService from '../services/UsuarioService'
import DependenciaService from '../services/DependenciaService'
//-----
import 'jquery/dist/jquery.min.js';
//Datatable Modules
//import "datatables.net-dt/js/dataTables.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//-----
//-----------------------------
//Spinner
//-----------------------------
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';


const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
`;

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;

const Spinner = styled.div`
  border: 3px solid orangered;
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite ${spin};
`;
//-----------------------

class ListUsuarioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            idres: '_add', usuarios: [], dependencias: [], optionsDependencias: [], isLoadData: false
        }
        this.addUsuario = this.addUsuario.bind(this);
        this.editUsuario = this.editUsuario.bind(this);
        this.deleteUsuario = this.deleteUsuario.bind(this);
    }

    deleteUsuario(id) {
        //alert('el id borrar ' + id)
        //alert('usuarios => ' + JSON.stringify(this.state.usuarios));


        UsuarioService.deleteUsuario(id).then(res => {
            //alert('aca2')
            window.location.reload(false);
            //this.props.history.push('/usuarios');
            //alert('aca 4')
        });
        //alert('aca3');
        /*
        console,log(JSON.stringify(this.state.usuarios));
	
    	
         UsuarioService.deleteUsuario(id)
         
         this.props.history.push('/usuarios');
         UsuarioService.getUsuarios().then((res) => {
             alert(res.data)
            this.setState({ usuarios: res.data});
        });
        //this.props.history.push(`/usuarios/`)
        */

    }
    viewUsuario(id) {
        this.props.history.push(`/view-usuario/${id}`);
    }
    editUsuario(id) {
        this.props.history.push(`/add-usuario/${id}`);
    }

    componentDidMount() {
        UsuarioService.getUsuarios().then((res) => {
            this.setState({ usuarios: res.data, isLoadData: true })

            $('#example').DataTable(
                {
                    "pagingType": "full_numbers",
                    "order": [[0, "desc"]],
                    "columnDefs": [
                        {
                            "targets": [0],
                            "visible": false,
                            "searchable": false
                        }
                    ]
                }
            )

        });
        DependenciaService.getDependencias().then((res) => {
            this.setState({ dependencias: res.data });
        });
        /*
        for (let i=0;i<this.state.dependencias.length;i++){
            this.state.optionsDependencias[i]= { value: this.state.dependencias[i].id_dependencia+'', label: this.state.dependencias[i].dependencia+'' };
        }
         */
        //initialize datatable
        /*	
            $(document).ready(function () {
                setTimeout(function(){
                //	$.noConflict();
                $('#example').DataTable(
                    {
                    "pagingType": "full_numbers",
                      "order": [[ 0, "desc" ]],
                    "columnDefs": [
                        {
                            "targets": [ 0 ],
                            "visible": false,
                            "searchable": false
                        }
                    ]
                    }
                );
    
                 } ,9000);
    */

        /*
        $(document).ready(function() {
    var table = $('#example').DataTable( {
        responsive: true
    } );
 */
        //new $.fn.dataTable.FixedHeader( table );
        //} );
    }
    findDependenciaById(id_depend) {
        let depend = 'N.A.';
        for (let i = 0; i < this.state.dependencias.length; i++) {
            if (this.state.dependencias[i].id_dependencia === id_depend) {
                depend = this.state.dependencias[i].dependencia;
                break;
            }
        }
        return depend;
    }

    addUsuario() {
        this.props.history.push('/add-usuario/_add');
    }
    newReservacionMaster(id, idCliente) {
        //alert("LLegando idCliente "+idCliente.id_usuario)
        this.setState({ 'id_usu': idCliente.id_usuario })

        localStorage.setItem("idCliente", idCliente.id_usuario);

        let cli = JSON.stringify(idCliente)
        //console.log ('llega99999999999999999999 '+cli)

        this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
    }


    render() {

        return (
            <div>
                <h2 className="text-center">Clientes</h2>
                <div className="row">
                    <button className="btn btn-primary" onClick={this.addUsuario}> Nuevo cliente</button>
                </div>
                <br></br>
                <div className="row">
                    {
                        !this.state.isLoadData ? <Spinner /> :

                            <table id="example" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: 100 + '%' }}>


                                <thead>
                                    <tr>
                                        <th> ID</th>
                                        <th> Documento</th>
                                        <th> Nombre</th>
                                        <th> Email</th>
                                        <th> Tel. 1</th>
                                        <th> Tel. 2</th>
                                        <th> Dependencia</th>
                                        <th> Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        this.state.usuarios.map(
                                            usuario =>
                                                <tr key={usuario.id_usuario}>
                                                    <td> {usuario.id_usuario} </td>
                                                    <td> {usuario.identificacion} </td>
                                                    <td> {usuario.razon_social} </td>
                                                    <td> {usuario.email}</td>
                                                    <td> {usuario.telefono1}</td>
                                                    <td> {usuario.telefono2}</td>
                                                    <td> {this.findDependenciaById(usuario.id_dependencia)}</td>
                                                    <td>
                                                        <button onClick={() => this.editUsuario(usuario.id_usuario)} className="btn btn-info  btn-sm">Editar </button>
                                                        {/*<button style={{marginLeft: "10px"}} onClick={ () => this.deleteUsuario(usuario.id_usuario)} className="btn btn-danger  btn-sm">Delete </button>*/}
                                                        {/*<button style={{marginLeft: "10px"}} onClick={ () => this.viewUsuario(usuario.id_usuario)} className="btn btn-info  btn-sm">View </button>*/}
                                                        <button onClick={() => this.newReservacionMaster(this.state.idres, (usuario))}
                                                            className="btn btn-warning  btn-sm">Reservar </button>
                                                    </td>
                                                </tr>
                                        )
                                    }
                                </tbody>
                            </table>

                    }
                    <br /><br /><br />
                </div>
                <br /><br /><br />
            </div>
        )
    }
}

export default ListUsuarioComponent
