import axios from 'axios';

const CONTRATO_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/contratoDetalles";
//const CONTRATO_API_BASE_URL = "http://localhost:8080/api/v1/contratoDetalles";
class ContratoDetalleService {

    getContratoDetalles(){
        return axios.get(CONTRATO_API_BASE_URL);
    }

    createContratoDetalle(contratoDetalle){
        return axios.post(CONTRATO_API_BASE_URL, contratoDetalle);
    }

    getContratoDetalleById(contratoDetalleId){
        return axios.get(CONTRATO_API_BASE_URL + '/' + contratoDetalleId);
    }
   

    updateContratoDetalle(contratoDetalle, contratoDetalleId){
		//alert('id_contratu '+contratoDetalleId+ ' ' +JSON.stringify(contratoDetalle))
        return axios.put(CONTRATO_API_BASE_URL + '/' + contratoDetalleId, contratoDetalle);
    }

    deleteContratoDetalle(contratoDetalleId){
        return axios.delete(CONTRATO_API_BASE_URL + '/' + contratoDetalleId);
    }
}

export default new ContratoDetalleService()
