import axios from 'axios';

const DEPENDENCIA_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/dependencias";
//const DEPENDENCIA_API_BASE_URL = "http://localhost:8080/api/v1/dependencias";


class DependenciaService {

    getDependencias(){
        return axios.get(DEPENDENCIA_API_BASE_URL);
    }

    createDependencia(dependencia){
        return axios.post(DEPENDENCIA_API_BASE_URL, dependencia);
    }

    getDependenciaById(dependenciaId	){
        return axios.get(DEPENDENCIA_API_BASE_URL + '/' + dependenciaId);
    }

    updateUsuario(dependencia, dependenciaId){
        return axios.put(DEPENDENCIA_API_BASE_URL + '/' + dependenciaId, dependencia);
    }

    deleteUsuario(dependenciaId){
        return axios.delete(DEPENDENCIA_API_BASE_URL + '/' + dependenciaId);
    }
}

export default new DependenciaService()
