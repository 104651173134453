import axios from 'axios';

const ERROR_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/erroresApp";
//const ERROR_API_BASE_URL = "http://localhost:8080/api/v1/erroresApp";
class ErrorAppService {

    getErrores(){
        return axios.get(ERROR_API_BASE_URL);
    }
    validaByIdRecursoAndFechaBetween(recursoId, fechaIni, fechaFin){
        return axios.get(ERROR_API_BASE_URL + '/reservacionesFechas/' + recursoId+'/'+fechaIni+'/'+fechaFin);
    }
	validaExisteIdentificacion(identificacion, idUsu){
		 return axios.get(ERROR_API_BASE_URL + '/validaIdentificacion/'+identificacion+'/'+idUsu);
	}
/*
    createReservacion(reservacion){
        return axios.post(RESERVACION_API_BASE_URL, reservacion);
    }

    getReservacionById(reservacionId){
        return axios.get(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }

    updateReservacion(reservacion, reservacionId){
        return axios.put(RESERVACION_API_BASE_URL + '/' + reservacionId, reservacion);
    }

    deleteReservacion(reservacionId){
        return axios.delete(RESERVACION_API_BASE_URL + '/' + reservacionId);
    }
*/
}

export default new ErrorAppService()
