import React, {Component} from 'react';
import './App.css';
import UsuarioService from './services/UsuarioService'
//jQuery libraries


import $ from 'jquery'; 
//For API Requests
import axios from 'axios';


class ListComponent extends Component {
  // State array variable to save and show data
    constructor(props) {
        super(props)

        this.state = {
                usuarios: []
        }
        this.addUsuario = this.addUsuario.bind(this);
        this.editUsuario = this.editUsuario.bind(this);
        this.deleteUsuario = this.deleteUsuario.bind(this);
    }
	
	deleteUsuario(id){
		alert('el id borrar '+id)
		//alert('usuarios => ' + JSON.stringify(this.state.usuarios));
		

		UsuarioService.deleteUsuario(id).then( res => {
			alert ('aca2')
			window.location.reload(false);
            //this.props.history.push('/usuarios');
			alert('aca 4')
        });
		alert('aca3');
		/*
		console,log(JSON.stringify(this.state.usuarios));
	
		
		 UsuarioService.deleteUsuarios(id)
		 
		 this.props.history.push('/usuarios');
		 UsuarioService.getUsuarios().then((res) => {
			 alert(res.data)
            this.setState({ usuarios: res.data});
        });
		//this.props.history.push(`/usuarios/`)
		*/
		
    }
    viewUsuario(id){
        this.props.history.push(`/view-usuario/${id}`);
    }
    editUsuario(id){
        this.props.history.push(`/add-usuario/${id}`);
    }
	
	
	
	
  componentDidMount() {
       //Get all users details in bootstrap table
        axios.get('http://192.168.1.3:8080/api/v1/usuarios').then(res => 
        {
          //Storing users detail in state array object
          this.setState({data: res.data});
        
        }); 
		//initialize datatable
		$(document).ready(function () {
			setTimeout(function(){
			$('#example').DataTable();
			 } ,1000);
		});
	}
	addUsuario(){
        this.props.history.push('/add-usuario/_add');
    }
  render(){
    //Datatable HTML
  return (
    <div className="MainDiv">
      <div class="jumbotron jumbotron-fluid text-center">
          <h2>USUARIOS</h2>
      </div>
      
      <div className="container">
     
		  <table id="example" class="table table-striped table-bordered dt-responsive nowrap" style={{ width:100 +'%' }}>
		  
          <thead>
            <tr>
              <th>ID</th>
             <th>Identificacion</th>
              <th>Username</th>
			   <th>Email</th>
			   <th>Telefono 1</th>
			   <th>Telefono 2</th>
			   <th>tipo</th>
			   <th>Opciones</th>
			   
            </tr>
          </thead>
          <tbody>
		 
          {this.state.data.map((result) => {
            return (
             
                 <tr>
                  <td>{result.id_usuario}</td>
                 <td>{result.identificacion}</td>
                  <td>{result.razon_social}</td>
				    <td>{result.email}</td>
				  <td>{result.telefono1}</td>
				  <td>{result.telefono2}</td>
				  <td>{result.id_tipo_usuario}</td>
				  
                </tr>
             
            )
          })}
           
            
          </tbody>
        </table>
          
        </div>
      </div>
  );
 }
}
export default ListComponent;
