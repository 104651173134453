import axios from 'axios';

const RECURSO_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/recursos";
//const RECURSO_API_BASE_URL = "http://localhost:8080/api/v1/recursos";
class RecursoService {

    getRecursos(){
        return axios.get(RECURSO_API_BASE_URL);
    }

    createRecurso(Recurso){
        return axios.post(RECURSO_API_BASE_URL, Recurso);
    }

    getRecursoById(RecursoId){
        return axios.get(RECURSO_API_BASE_URL + '/' + RecursoId);
    }

    updateRecurso(Recurso, RecursoId){
        return axios.put(RECURSO_API_BASE_URL + '/' + RecursoId, Recurso);
    }

    deleteRecurso(RecursoId){
        return axios.delete(RECURSO_API_BASE_URL + '/' + RecursoId);
    }
}

export default new RecursoService()
