import React, { Component } from 'react'
import UsuarioService from '../services/UsuarioService'
import DependenciaService from '../services/DependenciaService'
import ReservacionService from '../services/ReservacionService'
import RecursosReservacionesJSONService from '../services/RecursosReservacionesJSONService'
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery'

import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
//-----
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "./ListRecursosReservaciones.css"
import $ from 'jquery'; 
//-----

class ListRecursosReservacionesComponent extends Component {
    constructor(props) {
        super(props)
		//console.log('F inicio '+this.props.match.params.fIni);
		//console.log('F fin '+this.props.match.params.fFin);
		console.log('PERIODO '+this.props.match.params.periodo);
        this.state = {
			isOpen: false,
			
			reservacion: undefined,
			ocupacion:[],
			usuarioCrea:undefined,
			//fIni: this.props.match.params.fIni,
			//fFin: this.props.match.params.fFin,
			periodo : this.props.match.params.periodo,
                recursosReservaciones: [], usuarios: [], optionsClientes: []
        }
        this.addReservacionMaster = this.addReservacionMaster.bind(this);
		//addReservacionMaster
		//this.verReservacion = this.verReservacion.bind(this);
       // this.editReservacion = this.editReservacion.bind(this);
       // this.deleteReservacion = this.deleteReservacion.bind(this);
    }

	openModal = () => this.setState({ isOpen: true });
	/*
	openModal (id_reservacion) { 
		
		ReservacionService.getReservacionById(id_reservacion).then((res) => {
            this.setState({ reservacion: res.data})

		});
		
		this.setState({ isOpen: true })
	}
	*/
	closeModal = () => this.setState({ isOpen: false });
  
    deleteReservacion(id){
		alert('el id borrar '+id)
		//alert('usuarios => ' + JSON.stringify(this.state.usuarios));
		

		ReservacionService.deleteReservacion(id).then( res => {
			//alert ('aca2')
			window.location.reload(false);
            //this.props.history.push('/usuarios');
			//alert('aca 4')
        });
		alert('aca3');
		
    }
    viewReservacion(id){
        this.props.history.push(`/view-reservacion/${id}`);
    }

	listarRecursosReservaciones(pperiodo){
		//alert('listahdo '+pperiodo)
		//--------------------------------
        //this.props.history.push(`/recursosReservaciones/${periodo}`);
		//window.location.reload(false);
		//--------------------------------
		this.setState({ periodo : pperiodo});
		RecursosReservacionesJSONService.getRecursosReservacionesPeriodo(pperiodo+'').then((res) => {
            this.setState({ recursosReservaciones: res.data});
			this.setState({ocupacion: this.traeOcupacion()})
		});
		
		
    }
	verReservacion(id_reservacion){
		console.log ('aca zzzz'+id_reservacion)
		ReservacionService.getReservacionById(id_reservacion).then((res) => {
            this.setState({ reservacion: res.data})
			this.setState({isOpen: true})
			UsuarioService.getUsuarioById(this.state.reservacion.idUsuarioCrea).then((res) =>{
				this.setState({usuarioCrea: res.data})
				console.log('usuRes '+JSON.stringify(this.state.usuarioCrea))
			});
			
			//if(!this.state.isOpen){
			//	this.openModal()
			//}
		});
		//this.setState({isOpen: true})
	}

    editReservacion(id, idCliente){
	   // alert('cccc');
		     	let cli = JSON.stringify(idCliente)
	    		console.log ('llega99999999999999999999 '+cli)
		   
        this.props.history.push(`/add-reservacion/${id}/${cli}`);
    }

/*
	fetchReservaciones = async () => {
		        const res = await fetch("http://localhost:8080/api/v1/reservaciones");
		        const data = await res.json();
				console.log('XXXXXXX'+JSON.parse(data));
		        try {
				            this.setState({reservaciones: data.results});
				        } catch (err) {
						            console.log(err);
						        }
				console.log('XXXXXXXyyyyyzzzz'+JSON.stringify(data));
				return data;
		    };

*/

	//=========
	
	firstRecursoReservacion() {
		let primero = []
		primero = this.state.recursosReservaciones[0];
		//let primeroJ= JSON.parse(JSON.stringify(primero));
		console.log('primero J '+JSON.stringify(primero))
		return (primero);
  

	}
	traeOcupacion() {
		let recursosReservaciones = []
		let ocupacion=[]
		let dd=[]
		recursosReservaciones = this.state.recursosReservaciones;
		//let primeroJ= JSON.parse(JSON.stringify(primero));
		console.log('reservaciones cant '+recursosReservaciones.length)
		recursosReservaciones.map(recursosReservacion =>
			JSON.parse(recursosReservacion.datosReservas).map(function(datoReserva, index) {
				console.log('dato reservz '+JSON.stringify(datoReserva))
				dd = Object.entries(datoReserva)
				console.log('dd '+JSON.stringify(dd))
			
				// dd = Object.entries(datoReserva)
				//console.log('dd '+JSON.stringify(dd))
			if(ocupacion[index]==null){
					ocupacion[index]=0
				}
				
				if(datoReserva.id_estado_reservacion===1 || datoReserva.id_estado_reservacion===2){
					ocupacion[index]=recursosReservacion.nroHabitacion+ocupacion[index]
				}
			}
				
			)
			
		)
		console.log('tot ocupa '+ console.log(JSON.stringify(ocupacion)))
		return ocupacion;
  

	}
	
	firstRecursoReservacionF() {
	
		let miPrimero= this.state.recursosReservaciones.filter(
                                        recursoReservacion => 
										recursoReservacion.idRecurso===1
										)
		return miPrimero
	}
	
	getPeriodos(){
		let fechaPeriodo;
		let arrPer ;
		let periodo;
		let Periodos = [];
		let i = 0;
		let anio;
		let mes;
		this.firstRecursoReservacionF().map( recursoReservacion => 
                                        
			JSON.parse(recursoReservacion.datosReservas).map( datoReserva =>
													
				fechaPeriodo=datoReserva.fecha+''
			)
												
            )
		//console.log('fechitas fechitas '+fechaPeriodo);
		
		arrPer =  String(fechaPeriodo).split('-');
		anio=arrPer[0];
		mes = arrPer[1];
		let pers = new Array()
		pers[2] = anio+'-'+mes
		//console.log("ANIO "+anio+" MES "+mes)
		
		pers[1] = this.periodoAnterior(pers[2]);//ES EL ACTUAL OJO
		pers[0] = this.periodoAnterior(pers[1]);
		pers[3] = this.periodoSiguiente(pers[2]);
		pers[4] = this.periodoSiguiente(pers[3]);
		pers[5] = this.periodoSiguiente(pers[4]);
		pers[6] = this.periodoSiguiente(pers[5]);
		pers[7] = this.periodoSiguiente(pers[6]);
		pers[8] = this.periodoSiguiente(pers[7]);
		pers[9] = this.periodoSiguiente(pers[8]);
		pers[10] = this.periodoSiguiente(pers[9]);
		pers[11] = this.periodoSiguiente(pers[10]);
		pers[12] = this.periodoSiguiente(pers[11]);
		
		return pers;
	}
	
	periodoSiguiente(periodo){
		
		let arrPer =  String(periodo).split('-');
		let anio=parseInt(arrPer[0]);
		let mes = parseInt(arrPer[1]);
		if (mes < 12){
			mes ++;
		}else{
			mes = 1;
			anio ++;
		}
		let smes = String(mes);
		if (smes.length<2){
			smes = '0'+smes;
		}
		return anio+'-'+smes;
		
	}
	periodoAnterior(periodo){
		let arrPer =  String(periodo).split('-');
		let anio=parseInt(arrPer[0]);
		let mes = parseInt(arrPer[1]);
		if (mes > 1){
			mes --;
		}else{
			mes = 12;
			anio --;
		}
		let smes = String(mes);
		if (smes.length<2){
			smes = '0'+smes;
		}
		return anio+'-'+smes;
		
	}
	acumulaOcupacion(index,cap){
		let ocupados = [...this.state.ocupacion];
		// 2. Make a shallow copy of the item you want to mutate
		let ocupa = {...ocupados[index]};
		// 3. Replace the property you're intested in
		ocupa.numero = cap;
		// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
		ocupados[index] = ocupa;
		// 5. Set the state to our new copy
		this.setState({ocupacion:ocupados});
		console.log('ocupacion tot'+JSON.stringify(this.state.ocupacion))
		return index;
	}

	//=========
    componentDidMount(){
		//ESTO FUNCIONA OKOKOKOKOKOK
		/*
	    RecursosReservacionesService.getRecursosReservacionesIniFin(this.state.fIni, this.state.fFin).then((res) => {
            this.setState({ recursosReservaciones: res.data});
		});
		*/
		RecursosReservacionesJSONService.getRecursosReservacionesPeriodo(this.state.periodo+'').then((res) => {
            this.setState({ recursosReservaciones: res.data});
			this.setState({ocupacion: this.traeOcupacion()})
			
		});
		/*
		ReservacionService.getReservacionById(72).then((res) => {
            this.setState({ reservacion: res.data})
			//this.openModal()
		});
		*/
		/*
		RecursosReservacionesService.getRecursosReservaciones().then((res) => {
            this.setState({ recursosReservaciones: res.data});
       
		});
		*/
	   // this.fetchReservaciones();
/*	
	    UsuarioService.getUsuarios().then((res)=> {
			this.setState({ usuarios: res.data});
		})
*/		
		
	
		//initialize datatable
	    //console.log('MMMMMNNNNNNNNMMMMM'+(this.state.recursosReservaciones));
		//$(document).ready(function () {
			setTimeout(function(){
			$('#example').DataTable(
			{
			"order": [[ 0, "desc" ]]
			}
			);
			 } ,5000);
			 $('#example200').DataTable(
			{
			"order": [[ 0, "desc" ]]
			}
			);
		//});
    }
	/*
	findUsuarioById(id_usu){
		let usuario = 'N.A.';
		for (let i=0;i<this.state.usuarios.length;i++){
			if (this.state.usuarios[i].id_usuario === id_usu){
				usuario = this.state.usuarios[i];
				break;
			}
		}
		return usuario;
	}

	addReservacion(){
		//alert('iiiiii');
		let id='_add';
		let cli=JSON.stringify("{identificacion:'',razon_social:'',id_usuario:0}");
		console.log('USU '+cli)
           
        this.props.history.push(`/add-reservacionMaster/${id}/${'0'}`);
    
    }
*/

	//--------------

    addReservacionMaster(){
		//alert('iiiiii');
		let id='_add';
		let cli='{"identificacion":"","razon_social":"","id_usuario":"**"}';
		console.log('USU '+cli)
           
        this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
    
    }
	//--------------
	editReservacionMaster(id, idCliente){
	   // alert('cccc');
		     	let cli = JSON.stringify(idCliente)
	    		console.log ('llega99999999999999999999 '+cli)
		   
        this.props.history.push(`/add-reservacionMaster/${id}/${cli}`);
    }
    render() {
	let ocupacion=[];

        return (
            <div>
                 <h2 className="text-center">Ocupacion</h2>
				       

      <Modal show={this.state.isOpen} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
		  Reservación Nro: {this.state.reservacion===undefined?0:this.state.reservacion.idReservacion} - 
	{this.state.reservacion===undefined?'':this.state.reservacion.idEstadoReservacion.idEstadoReservacion===1?<span style={{"color": "#E74C3C"}}>{this.state.reservacion.idEstadoReservacion.estado}</span>:this.state.reservacion.idEstadoReservacion.idEstadoReservacion===2?<span style={{"color": "#5DADE2"}}>{this.state.reservacion.idEstadoReservacion.estado}</span>:''}
		  
		  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<table id="example200" className="table table-striped table-bordered display compact" style={{ width:350+'px' }}>
		<tbody>



		<tr><td>MASTER ID  </td><td> {this.state.reservacion===undefined?0:this.state.reservacion.reservacionMaster.idReservacionMaster}

		<button onClick={ () => this.editReservacionMaster(this.state.reservacion.reservacionMaster.idReservacionMaster,(this.state.reservacion.reservacionMaster.cliente))} className="btn btn-info  btn-sm">Editar </button>
		</td></tr>


		<tr><td>Registrada por:</td><td>  <span style={{"color": "#000080",  "fontWeight": "600", "font-size": "0.70em"}}>{this.state.reservacion===undefined?0:this.state.usuarioCrea===undefined?'':this.state.usuarioCrea.razon_social.substring(0, 15)} ( {this.state.reservacion===undefined?0:(this.state.reservacion.registDate+'').substring(0,10)})</span></td></tr>
		<tr><td>Identificacion </td><td>{this.state.reservacion===undefined?0:this.state.reservacion.idCliente.identificacion}</td></tr>
		<tr><td>Nombre </td> <td>{this.state.reservacion===undefined?0:this.state.reservacion.idCliente.razon_social}</td></tr>
		<tr><td>Email </td><td> {this.state.reservacion===undefined?0:this.state.reservacion.idCliente.email}</td></tr>
		<tr><td>Telefono </td> <td>{this.state.reservacion===undefined?0:this.state.reservacion.idCliente.telefono1} - {this.state.reservacion===undefined?0:this.state.reservacion.idCliente.telefono2}</td> </tr>
		<tr><td>Fechas  </td><td>Desde:<span style={{"color": "#006400"}}>{this.state.reservacion===undefined?0:this.state.reservacion.fechaIni}</span> Hasta:<span style={{"color": "#FF4500"}}>{this.state.reservacion===undefined?0:this.state.reservacion.fechaFin}</span> </td> </tr>
		<tr><td>Valor </td><td> {this.state.reservacion===undefined?0:this.state.reservacion.valor}</td></tr>
		<tr><td>Observaciones </td><td> {this.state.reservacion===undefined?0:this.state.reservacion.observaciones}</td></tr>
		<tr><td>Habitacion: <span style={{"color": "#FF0000",  "fontWeight": "600", "font-size": "0.975em"}}> {this.state.reservacion===undefined?0:this.state.reservacion.idRecurso.descripcionS} </span></td><td>{this.state.reservacion===undefined?0:this.state.reservacion.idRecurso.descripcionL}</td></tr>
		</tbody>
		</table>
		</Modal.Body>
       
      </Modal>
				 
                 <div className = "row">
                    <button className="btn btn-primary" onClick={this.addReservacionMaster}> Nueva reservacion</button>
                 </div>
                 <br></br>
				     

    
                 <div className = "row">
                      <div  style={{ whiteSpace: 'nowrap' }}>
					  {
						  //console.log('LOS PERIODOsss '+JSON.stringify(this.getPeriodos()))
						  this.getPeriodos().map( periodo =>
							
							<button type="button" onClick={ () => this.listarRecursosReservaciones(periodo)}  className="btn btn-outline-info  btn-sm"> {periodo}</button>
						  )
					  }
					  </div>
						<table id="example100" className="table table-striped table-bordered dt-responsive nowrap display compact" style={{ width:100 +'%' }}>
		  

                           
                            <tbody>
                                {
									
                                   this.state.recursosReservaciones.map(
                                        (recursoReservacion,index) => 
										index%10===0?
								<>
								<tr>
								<th style={{"backgroundColor": "#FFD700",  "font-size": "0.875em"}}> Nro. </th>
								<th style={{"backgroundColor": "#FFD700",  "font-size": "0.675em"}}> Cap. </th>
								{
									

											JSON.parse(recursoReservacion.datosReservas).map((datoReserva, index) =>
												index >=1 &&
												<th style={{"backgroundColor": "#9CCC65",  "font-size": "0.575em"}}> {(datoReserva.fecha)}</th>
												
											)
											
													
                                    
													
								}
								<th style={{"backgroundColor": "#FFD700",  "font-size": "0.875em"}}> Nro. </th>
								<th style={{"backgroundColor": "#FFD700",  "font-size": "0.675em"}}> Cap. </th>
                                </tr>
								
								
								<tr key = {recursoReservacion.idRecurso}>
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.descripcionS} </td> 
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.nroHabitacion} </td> 
												{ 
													
													JSON.parse(recursoReservacion.datosReservas).map( (datoReserva, index, arreglo) =>
														(index>=1&&index<34)?
															(arreglo[index-1]['id_reservacion'] == null && datoReserva.id_reservacion != null)?
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															:
																(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion == null)?
																	<td style={arreglo[index-1]['id_estado_reservacion']===1?{"backgroundColor": "#f9532f"}:arreglo[index-1]['id_estado_reservacion']===2?{"backgroundColor": "#5DADB0"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(arreglo[index-1]['id_reservacion'])}</a> </td>
																:
																	(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion == arreglo[index-1]['id_reservacion'] )?
																		<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															
																	:
																		(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion != arreglo[index-1]['id_reservacion'] )?
																			<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}<br />{arreglo[index-1]['id_reservacion']}</a> </td>
																		:
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
														:
															index > 0 &&
															( datoReserva.id_reservacion != null)&&
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															
													)
													
												}
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.descripcionS} </td> 
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.nroHabitacion} </td> 
											 
                                        </tr>
										</>

										:
                                        <tr key = {recursoReservacion.idRecurso}>
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.descripcionS} </td> 
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.nroHabitacion} </td> 
												{ 
													
													JSON.parse(recursoReservacion.datosReservas).map( (datoReserva, index, arreglo) =>
														(index>=1&&index<34)?
															(arreglo[index-1]['id_reservacion'] == null && datoReserva.id_reservacion != null)?
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															:
																(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion == null)?
																	<td style={arreglo[index-1]['id_estado_reservacion']===1?{"backgroundColor": "#f9532f"}:arreglo[index-1]['id_estado_reservacion']===2?{"backgroundColor": "#5DADB0"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(arreglo[index-1]['id_reservacion'])}</a> </td>
																:
																	(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion == arreglo[index-1]['id_reservacion'] )?
																		<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															
																	:
																		(arreglo[index-1]['id_reservacion'] != null && datoReserva.id_reservacion != arreglo[index-1]['id_reservacion'] )?
																			<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}<br />{arreglo[index-1]['id_reservacion']}</a> </td>
																		:
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
														:
															index > 0 &&
															( datoReserva.id_reservacion != null)&&
																<td style={datoReserva.id_estado_reservacion===1?{"backgroundColor": "#E74C3C"}:datoReserva.id_estado_reservacion===2?{"backgroundColor": "#5DADE2"}:{"backgroundColor": "#F1F8E9"}}> <a href="#" onClick={ () => this.verReservacion(datoReserva.id_reservacion)}>{(datoReserva.id_reservacion)}</a> </td>
															
													)
												}
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.descripcionS} </td> 
											<td style={{"backgroundColor": "#F4FA58",  "font-size": "0.875em"}}> {recursoReservacion.nroHabitacion} </td> 
											 
                                        </tr>
                                    )
									
									
										
												
													
											
											
											
                                       
										
                                }
								<tr>
											<td style={{"backgroundColor": "#F0FFFF", "color":"#006400" ,  "font-size": "0.875em"}} colspan="2"> Ocupacion</td>
													{
													this.state.ocupacion.map( ocupa =>
													
														<td style={{"backgroundColor": "#006400", "color": "#F0FFFF",  "font-size": "0.875em"}}>{ocupa} </td>
														
													)
												
													}
												
											<td style={{"backgroundColor": "#F0FFFF", "color":"#006400" ,  "font-size": "0.875em"}} colspan="2">Ocupacion</td>
											 
                                        </tr>
                            </tbody>
                        </table>
						
						<br /><br /><br />

                 </div>
			     <br /><br /><br />
            </div>
			
        )
    }
}

export default ListRecursosReservacionesComponent
