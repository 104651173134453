import React, { Component, useState } from 'react'
import ReservacionService from '../services/ReservacionService';
import UsuarioService from '../services/UsuarioService';
import RecursoService from '../services/RecursoService'
import ErrorAppService from '../services/ErrorAppService'
import SingleSelect from "./selectUsu";
import Select from 'react-select'
//import AsyncSelect from "react-select/lib/Async";
//import AsyncSelect from 'react-async-select'
//import SelectUsuarios from './SelectUsuarios'


//import _ from "lodash";

class CreateReservacionComponent extends Component {
    constructor(props) {
        super(props)
		//alert ('EL ID LLEGA '+this.props.match.params.id)
		console.log ('EL ID LLEGA '+this.props.match.params.id)
		console.log ('EL cliente lLEGA '+JSON.stringify(this.props.match.params.cli))
        this.state = {
            // step 2
            id: this.props.match.params.id,
			
            idRecurso: undefined,
			id_recurso : 0,
            idCliente: JSON.stringify(this.props.match.params.cli),
			id_cliente : 0,
            fechaIni: '',
			fechaFin: '',
            observaciones: '',
			idEstadoReservacion: undefined,
			id_estado_reservacion : 1,
            valor: 0,
			observacion : '',
			inputValue : '',
			selectedValue : null,
			noLoadOk : true,
			usuarios: [], optionsUsuarios: [], recursos : [], optionsRecursos : []

        }
        this.changeIdRecursoHandler = this.changeIdRecursoHandler.bind(this);
		this.changeIdClienteHandler = this.changeIdClienteHandler.bind(this);
        this.changeFechaIniHandler = this.changeFechaIniHandler.bind(this);
		this.changeFechaFinHandler = this.changeFechaFinHandler.bind(this);
		this.changeValorHandler = this.changeValorHandler.bind(this);
		this.changeObservacionHandler = this.changeObservacionHandler.bind(this);
		
		//this.changeIdEstadoReservacionHandler = this.changeIdEstadoReservacionHandler.bind(this);
		
		

    }

    // step 3
    componentDidMount(){
		RecursoService.getRecursos().then((res)=> {
			this.setState({ recursos: res.data});
		});
		UsuarioService.getUsuarios().then((res)=> {
			this.setState({ usuarios: res.data});
			this.setState({optionsUsuarios: this.renderListUsu(), noLoadOk: false});
		});
        // step 4
		//alert ('cargando ID '+this.state.id)
        if(this.state.id === '_add'){
			//alert('es nuevo');
            //return
			this.setState({value : 0, idUsuarioCrea: sessionStorage.getItem('id_usuario') });
        }else{
			//alert('para actualizar xx'+this.state.id);
            ReservacionService.getReservacionById(this.state.id).then( (res) =>{
                let reservacion = res.data;
				//let 
                this.setState({
					idReservacion:reservacion.idReservacion,
					idRecurso: reservacion.idRecurso,
					idUsuarioCrea:reservacion.idUsuarioCrea,
					id_recurso : reservacion.idRecurso.idRecurso,
                    idCliente: reservacion.IdCliente,
					//id_cliente : reservacion.IdCliente.id_usuario,
                    fechaIni : reservacion.fechaIni,
					fechaFin : reservacion.fechaFin,
					valor : reservacion.valor,
					observaciones : reservacion.observaciones,
					idEstadoReservacion : reservacion.idEstadoReservacion,
					
					selectedRecValue : {value: reservacion.idRecurso.idRecurso, label : reservacion.idRecurso.descripcionS+'-'+reservacion.idRecurso.descripcionL}
					
					
                });
				console.log('reservacion inicial=> ' + JSON.stringify(reservacion));
				console.log('reservacion seleccionada=> ' + JSON.stringify(this.state.selectedRecValue));
            });
			 
			
        }  

		
	
		
		
    }
	
    saveOrUpdateReservacion = (e) => {
        e.preventDefault();
		//console.log('id_recurso zz '+JSON.stringify(localStorage.getItem('id_rec')))
		//let ire=localStorage.getItem('id_rec');
		//alert('busca recurso id '+ire);
		//console.log('id_usuario zz '+JSON.stringify(localStorage.getItem('id_usu')))
		//let ius=localStorage.getItem('id_usu')
		//let us= this.findUsuarioById(ius)
		//let re =this.findRecursoById(ire)
		console.log('EN CREACION RESERVACIONES '+localStorage.getItem('id_usu'))
		let clie = this.findUsuarioById(localStorage.getItem('id_usu')); 
		
		if(!clie){
			
			clie=this.state.idCliente;
		}
		let recu=this.findRecursoById(localStorage.getItem('id_rec')); 
		console.log('RECU '+JSON.stringify(recu))
		if (!recu){
			clie=this.state.idRecurso;
		}
		
        let reservacion = {
				 
		
			
			idCliente:clie,
			idRecurso: recu, 
			//console.log('Recurso '+JSON.stringify(localStorage.getItem('id_rec'))
			idUsuarioCrea : this.state.idUsuarioCrea,
			idReservacion : this.state.idReservacion,
			fechaIni : this.state.fechaIni,
			fechaFin: this.state.fechaFin,
			valor : this.state.valor,
			observaciones: this.state.observaciones,
			idEstadoReservacion:{"idEstadoReservacion":1,"codigo":"1","estado":"Reservada"},
			
			observaciones : this.state.observaciones
			

			};
			//id_cliente: this.state.idCliente.id_usuario,
			//id_recurso : this.state.idRecurso.idRecurso,
			//id_estado_reservacion : this.state.id_estado_reservacion

        console.log('reservacion zz=> ' + JSON.stringify(reservacion));

        // step 5
		alert ('Statte ID:'+this.state.id)
        if(this.state.id === '_add'){
           
			/*
		   ReservacionService.createReservacion(reservacion).then(res =>{
                this.props.history.push('/reservaciones');
            });
			*/
			let errorApp
			console.log("idRecurso acas "+JSON.stringify(reservacion.idRecurso))
			//--------------------------
			 ErrorAppService.validaByIdRecursoAndFechaBetween(reservacion.idRecurso.idRecurso, reservacion.fechaIni, reservacion.fechaFin).then( (res) =>
				{
					//console.log('aki aki '+res.data+'')
					errorApp = res.data
					Object.freeze(errorApp)
					//console.log('errorAPP '+errorApp.idErrorApp)
					if(parseInt(errorApp.idErrorApp)>0){
						alert ("ERROR: "+errorApp.descripcionErrorApp)
					}else{
						console.log('CREANDO RESERVACION '+JSON.stringify(reservacion))
						ReservacionService.createReservacion(reservacion).then(res =>{
							this.props.history.push('/reservaciones');
						});
					}
				}
				)
        }else{
           
			ReservacionService.updateReservacion(reservacion, this.state.id).then( res => {
                this.props.history.push('/reservaciones');
            });
			 /*
			let errorApp
			console.log("idRecurso "+JSON.stringify(reservacion.idRecurso))
			//--------------------------
			ErrorAppService.validaByIdRecursoAndFechaBetween(reservacion.idRecurso.idRecurso, reservacion.fechaIni, reservacion.fechaFin).then( (res) =>
				{
					console.log('aki2 aki2 '+res.data+'')
					errorApp = res.data
					Object.freeze(errorApp)
					console.log('errorAPP '+errorApp.idErrorApp)
					if(parseInt(errorApp.idErrorApp)>0){
						alert ("ERROR "+errorApp.descripcionErrorApp)
					}else{
						ReservacionService.updateReservacion(reservacion, this.state.id).then( res => {
							this.props.history.push('/reservaciones')
						});
					}
				}
			
			)
			*/
			
        }
    }
   
	updateEstado2ReservacionHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ReservacionService.updateEstadoReservacion(this.state.id, 2).then( res => {
							this.props.history.push('/reservaciones')
						});
	}
	updateEstado4ReservacionHandler = (event) => {
		event.preventDefault()
		//alert('llamando')
		ReservacionService.updateEstadoReservacion(this.state.id, 4).then( res => {
							this.props.history.push('/reservaciones')
						});
	}
	updateEstado44ReservacionHandler = (event) => {
		event.preventDefault()
		alert('llamando xyz:'+this.state.id)
		
	//------------------------------
	//
	//------------------------------	
	ReservacionService.updateEstadoReservacion(this.state.id, 4).then( res => {
							//this.props.history.push('/reservaciones')
							this.setState({id: '_add'});
							//this.state.id='_add'
							alert ('aca xy'+this.state.id)
						});
						alert ('aca xyz'+this.state.id)
	}
	renderList() {
			return (this.state.recursos.map(data =>({label:data.descripcionS+'-'+data.descripcionL, value : data.idRecurso})))
	}
	renderListUsu() {
			return (this.state.usuarios.map(data =>({label: data.razon_social+'-'+data.identificacion, value : data.id_usuario})))
	}
    changeIdRecursoHandler= (event) => {
		alert ('executa');
        this.setState({id_recurso: event.target.value});
		this.setState({idRecurso : undefined});
		alert ('valor '+event.target.value)
		for (let i=0;i<this.state.recursos.length;i++){
			if(this.state.recursos[i].idRecurso===this.state.id_recurso){
				this.setState({idRecurso : this.state.recursos[i]});
				alert('encontro');
				
			}
		}
		
        //console.log('recurso=> ' + JSON.stringify(idRecurso));
		
    }

    changeIdClienteHandler= (event) => {
		//alert('aca identifica '+ event.target.value);
        this.setState({id_cliente: event.target.value});
    }

    changeFechaIniHandler= (event) => {
        this.setState({fechaIni: event.target.value});
    }
    changeFechaFinHandler= (event) => {
        this.setState({fechaFin: event.target.value});
    }
	changeValorHandler= (event) => {
		//alert('actualiza valor-->'+event.target.value)
        this.setState({valor: event.target.value});
    }
	changeObservacionHandler= (event) => {
        this.setState({observaciones: event.target.value});
    }
	changeIdEstadoReservacionHandler= (event) => {
        this.setState({id_estado_reservacion: event.target.value});
    }


	defaultSelectId_recurso(id_recurso){
		//alert ('xxxxid_dependencia '+id_dependencia)
		let opcionesx=this.renderList();
		
		for (let i=0; i< opcionesx.length; i++){
			if(opcionesx[i].value===id_recurso){
				
				console.log('depe '+opcionesx[i].label);
				return opcionesx[i]
			}
		}
		return {value:0,label:'Seleccione opcion...'};
	}

  //***************************************


	findRecursoById(id_rec){
		//alert (' buscando id_recurso '+id_rec +' en '+this.state.recursos.length);
		let recurso = undefined;
		for (let i=0;i<this.state.recursos.length;i++){
			let idrec=this.state.recursos[i].idRecurso;
			//console.log(i + '--'+idrec +' BUSCANDO '+ parseInt(id_rec))
			
			if (parseInt(this.state.recursos[i].idRecurso) == parseInt(id_rec)){
				recurso = this.state.recursos[i];
				console.log('recurso enconrado '+(recurso.nroHabitacion))
				return recurso;
			}
		}
		
		return recurso;
	}

	findUsuarioById(id_usu){
		//alert ('buscando id_usuario '+id_usu+' en '+this.state.usuarios.length);
		let usuario = null;
		for (let i=0;i<this.state.usuarios.length;i++){
			let idu=parseInt(this.state.usuarios[i].id_usuario);
			//console.log(i+' buscando '+id_usu+' en '+parseInt(this.state.usuarios[i].id_usuario))
			if (parseInt(this.state.usuarios[i].id_usuario) == parseInt(id_usu)){
				usuario = this.state.usuarios[i];
				return usuario;
			}
		}
		alert ('usuario enconrado '+JSON.parse(usuario))
		return usuario;
	}

    getTitle(){
        if(this.state.id === '_add'){
            return <h3 className="text-center">Nueva reservacion</h3>
        }else{
            return <h3 className="text-center">Actualiza reservacion</h3>
        }
    }

	// handle input change event
   handleRecInputChange = value => {
    //setValue(value);
	this.setState({inputRecValue : value});
  };

	cancel(){
        this.props.history.push('/reservaciones');
    }

  // handle selection
   handleRecChange = value => {
    //setSelectedValue(value);
	//alert('Cambiando ++++'+ JSON.stringify(value));
	console.log('EL RECURSO ES => ' + JSON.stringify(value));
	this.setState({selectedRecValue:value, id_recurso:value})
	//localStorage.setItem('id_rec',value);
	localStorage.setItem('id_rec',value.value)
	console.log('En storage ojojojo'+JSON.stringify(localStorage.getItem('id_rec')))
	
  }
    render() {
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Habitacion: </label>
											<Select 
											 defaultOptions
											value={this.state.selectedRecValue}
											options={this.renderList()} 
											onInputChange={this.handleRecInputChange}
											onChange={this.handleRecChange}
											isDisabled={(this.state.id !== '_add')}
											/>
                                        </div>

                                        <div className = "form-group">
                                            <label> Cliente: </label>
										    
											<SingleSelect idCliente = {JSON.parse(this.props.match.params.cli)} />
											 
										</div>
										<div className = "form-group">
                                        <label> Fecha entrada <span style={{"color": "green",  "font-size": "0.775em"}}>(mm/dd/yyyy)</span>: </label>
										      <input placeholder="2021-12-23" disabled={(this.state.id !== '_add')} type="date" name="fechaIni" className="form-control" 
                                                value={this.state.fechaIni} onChange={this.changeFechaIniHandler}/>
                                        </div>
										 <div className = "form-group">
											<label> Fecha salida <span style={{"color": "blue",  "font-size": "0.775em"}}>(mm/dd/yyyy)</span>: </label>
                                            <input placeholder="2021-12-25" disabled={(this.state.id !== '_add')} type="date" name="fechaFin" className="form-control" 
                                                value={this.state.fechaFin} onChange={this.changeFechaFinHandler}/>
												
                                        </div>
										<div className = "form-group">
											<label> Valor: </label>
                                            <input placeholder="150000" type="number" name="valor" className="form-control" 
                                                value={this.state.valor} onChange={this.changeValorHandler}/>
												
                                        </div>
										<div className = "form-group">
											<label> Observacion: </label>
                                            <input placeholder="" name="observaciones" className="form-control" 
                                                value={this.state.observaciones} onChange={this.changeObservacionHandler}/>
                                        </div>
									
                                        <button className="btn btn-primary" disabled={this.state.noLoadOk} name="new_reservacion" id="new_reservacion" onClick={this.saveOrUpdateReservacion}>Guardar</button>
										
										<button className="btn btn-success" disabled={(this.state.id === '_add')} onClick={this.updateEstado2ReservacionHandler} style={{marginLeft: "10px"}}>Confirmar</button>
										<button className="btn btn-danger"  disabled={(this.state.id === '_add')} onClick={this.updateEstado4ReservacionHandler} style={{marginLeft: "10px"}}>Anular</button>
										<br />
										<button className="btn btn-danger"  disabled={(this.state.id === '_add')} onClick={this.updateEstado44ReservacionHandler} style={{marginLeft: "10px"}}>Editar</button>
										<button className="btn btn-secondary" onClick={this.cancel.bind(this)}  style={{marginLeft: "10px"}}>Cancelar</button>
                                    </form>
									<br /><br /> <br />
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateReservacionComponent
