import React, { Component } from 'react'
import UsuarioService from '../services/UsuarioService'
import DependenciaService from '../services/DependenciaService'
import ReservacionService from '../services/ReservacionService'
import ContratoService from '../services/ContratoService'
//-----
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
//-----

import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';


const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
`;

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;

const Spinner = styled.div`
  border: 3px solid orangered;
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite ${spin};
`;



class ListContratoComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                contratos: [], usuarios: [], optionsClientes: [], isLoadData : false
        }
        this.addContrato = this.addContrato.bind(this);
        this.editContrato = this.editContrato.bind(this);
        this.deleteContrato = this.deleteContrato.bind(this);
    }

    deleteContrato(id){
		alert('el id borrar '+id)
		//alert('usuarios => ' + JSON.stringify(this.state.usuarios));
		

		ContratoService.deleteContrato(id).then( res => {
			//alert ('aca2')
			window.location.reload(false);
            //this.props.history.push('/usuarios');
			//alert('aca 4')
        });
		alert('aca3');
		
    }
	
    viewReservacion(id){
        this.props.history.push(`/view-reservacion/${id}`);
    }

    editContrato(id, idCliente){
	   // alert('cccc');
		     	let cli = JSON.stringify(idCliente)
	    		console.log ('llega99999999999999999999 '+cli)
		   
        this.props.history.push(`/add-contrato/${id}/${cli}`);
    }
	
/*
	fetchReservaciones = async () => {
		        const res = await fetch("http://localhost:8080/api/v1/reservaciones");
		        const data = await res.json();
				console.log('XXXXXXX'+JSON.stringify(data));
		        try {
				            this.setState({reservaciones: data.results});
				        } catch (err) {
						            console.log(err);
						        }
				console.log('XXXXXXXyyyyyzzzz'+JSON.stringify(data));
				return data;
		    };

	*/
    componentDidMount(){
	    ContratoService.getContratos().then((res) => {
            this.setState({ contratos: res.data, isLoadData : true});
       
			setTimeout(function(){
			$('#example').DataTable(
			{
			"order": [[ 0, "desc" ]]
			}
			);
			 } ,1000);
		});
	   // this.fetchReservaciones();
/*	
	    UsuarioService.getUsuarios().then((res)=> {
			this.setState({ usuarios: res.data});
		})
*/		
	
		//initialize datatable
	    //console.log('MMMMMNNNNNNNNMMMMM'+JSON.stringify(this.state.reservaciones));
		//$(document).ready(function () {
		//});
    }
	findContratoById(id_usu){
		let usuario = 'N.A.';
		for (let i=0;i<this.state.usuarios.length;i++){
			if (this.state.usuarios[i].id_usuario === id_usu){
				usuario = this.state.usuarios[i];
				break;
			}
		}
		return usuario;
	}


    addContrato(){
		//alert('iiiiii');
		let id='_add';
		let cli=JSON.stringify("{identificacion:'',razon_social:'',id_usuario:0}");
		console.log('USU '+cli)
           
        this.props.history.push(`/add-contrato/${id}/${'0'}`);
    
    }

    render() {
		
        return (
            <div>
                 <h2 className="text-center">Contratos</h2>
                 <div className = "row">
                    <button className="btn btn-primary" onClick={this.addContrato}> Nuevo contrato</button>
                 </div>
                 <br></br>
                 <div className = "row">
                      
					  
					  {
						 !this.state.isLoadData?<Spinner />:
						  
						<table id="example" className="table table-striped table-bordered dt-responsive nowrap display compact" style={{ width:100 +'%', "font-size": "0.90em" }}>
		  

                            <thead>
                                <tr>
									<th> Num </th>
                                    <th> Fecha</th>
								    <th> Cliente</th>
                                    <th> Contratante</th>
                                   	<th> Estado</th>
									<th> Vlr. total</th>
									<th> Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contratos.map(
                                        contrato => 
                                        <tr key = {contrato.idContrato}>
											<td> {contrato.idContrato} </td> 
											 <td> {contrato.fecha} </td> 
											
											 <td> {contrato.idCliente.razon_social} </td>  
                                             <td> {contrato.contacto} </td> 
											 
											  <td> {
												  contrato.idEstadoContrato.idEstadoContrato===1?
												  <span style={{"color":  "#29B6F6", "padding": "1px 1px 1px 1px","font-weight": "bold", "font-size": "0.90em"}}>{contrato.idEstadoContrato.estadoContrato}</span>
												  :contrato.idEstadoContrato.idEstadoContrato===2?
												  <span style={{"color": "#388E3C", "padding": "1px 1px 1px 1px","font-weight": "bold",  "font-size": "0.90em"}}>{contrato.idEstadoContrato.estadoContrato}</span>
												   :contrato.idEstadoContrato.idEstadoContrato===4?
												   <span style={{"color": "#FF5500","padding": "1px 1px 1px 1px", "font-weight": "bold",  "font-size": "0.90em"}}>{contrato.idEstadoContrato.estadoContrato}</span>
												   :
												    <span style={{"color": "#001111","padding": "1px 1px 1px 1px", "font-weight": "bold",  "font-size": "0.90em"}}>{contrato.idEstadoContrato.estadoContrato}</span>
												  }</td>
                                             <td style={{ "text-align": "right"}}> {contrato.vlrTotal}</td>
											 <td>
                                                 <button onClick={ () => this.editContrato(contrato.idContrato,(contrato.idCliente))} className="btn btn-info  btn-sm">Editar </button>
													 {/*<button style={{marginLeft: "10px"}} onClick={ () => this.deleteReservacion(reservacion.idReservacion)} className="btn btn-danger  btn-sm">Delete </button>*/}
													 {/*<button style={{marginLeft: "10px"}} onClick={ () => this.viewUsuario(usuario.id_usuario)} className="btn btn-info  btn-sm">View </button>*/}
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
					  }
						<br /><br /><br />

                 </div>
			     <br /><br /><br />
            </div>
			
        )
    }
}

export default ListContratoComponent
