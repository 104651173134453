import React, { Component } from 'react'
import UsuarioService from '../services/UsuarioService';
import DependenciaService from '../services/DependenciaService'
import ErrorAppService from '../services/ErrorAppService'
import Select from 'react-select'

class CreateUsuarioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            id_tipo_identificacion: 0,
            identificacion: '',
            razon_social: '',
			email: '',
            telefono1: '',
			telefono2: '',
            password: '',
			id_tipo_usuario: 0,
			loguea: 0,
			id_dependencia : 0,
			dependencias: [], optionsDependencias: []

        }
        this.changeId_tipo_identificacionHandler = this.changeId_tipo_identificacionHandler.bind(this);
		this.changeIdentificacionHandler = this.changeIdentificacionHandler.bind(this);
        this.changeRazon_socialHandler = this.changeRazon_socialHandler.bind(this);
		this.changeEmailHandler = this.changeEmailHandler.bind(this);
		this.changeTelefono1Handler = this.changeTelefono1Handler.bind(this);
		this.changeTelefono2Handler = this.changeTelefono2Handler.bind(this);
		
		this.changeLogueaHandler = this.changeLogueaHandler.bind(this);
		this.saveOrUpdateUsuario = this.saveOrUpdateUsuario.bind(this);
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '_add'){
			//alert('es nuevo');
            //return
			this.setState({value : 0});
        }else{
			//alert('para actualizar');
            UsuarioService.getUsuarioById(this.state.id).then( (res) =>{
                let usuario = res.data;
                this.setState({
					id_tipo_identificacion: usuario.id_tipo_identificacion,
                    identificacion: usuario.identificacion,
                    razon_social : usuario.razon_social,
					email : usuario.email,
					telefono1 : usuario.telefono1,
					telefono2 : usuario.telefono2,
					id_tipo_usuario : usuario.id_tipo_usuario,
					loguea : usuario.loguea,
					id_dependencia : usuario.id_dependencia,
					value : usuario.id_dependencia
					
                });
            });
			
        }  
		DependenciaService.getDependencias().then((res)=> {
			this.setState({ dependencias: res.data});
		});
		
		
    }
	
    saveOrUpdateUsuario = (e) => {
        e.preventDefault();
		
        let usuario = {
			id_tipo_identificacion: this.state.id_tipo_identificacion, 
			identificacion: this.state.identificacion,
			razon_social: this.state.razon_social,
			email: this.state.email,
			telefono1: this.state.telefono1,
			telefono2: this.state.telefono2,
			id_tipo_usuario: this.state.id_tipo_usuario,
			loguea: this.state.loguea,
			id_dependencia : this.state.id_dependencia
			//ACCCCCCAA 
			//emailId: this.state.emailId
			};
        console.log('usuario => ' + JSON.stringify(usuario))
		
		//----------------------------------------------------------------
		//VALIDA EXISTE IDENTIFICACION
		//----------------------------------------------------------------
		let errorApp;
		let iden_valida=this.state.identificacion.trim()
		alert ('ALIDANDO:'+iden_valida)
		let id_usua = 0;
		if (iden_valida === ''){
			//id_usu_valida = 0;
			alert ('ERROR : La identificación es un campo requerido')
		}else{
			
			if(this.state.id !== '_add'){
				id_usua = this.state.id
						
			}
			
			ErrorAppService.validaExisteIdentificacion(iden_valida, id_usua).then( (res) =>
			{
				//console.log('aki aki '+res.data+'')
		
				errorApp = res.data
				Object.freeze(errorApp)
				//console.log('errorAPP '+errorApp.idErrorApp)
				if(parseInt(errorApp.idErrorApp)>0){
					alert ("ERROR: Verificando "+iden_valida+' '+errorApp.descripcionErrorApp)
					if(this.state.id === '_add'){
						document.getElementById('identificacion').value=''
						//document.getElementById("identificacion").focus();
					}
				}else{
					//--------
					//CREANDO NUEVO REGISTRO O ACTUALIZANDO SEGUN CORRESPONDA
					//--------
					// step 5
					if(this.state.id === '_add'){
						UsuarioService.createUsuario(usuario).then(res =>{
							this.props.history.push('/usuarios');
						});
					}else{
						UsuarioService.updateUsuario(usuario, this.state.id).then( res => {
							this.props.history.push('/usuarios');
						});
					}

					//--------
				}
			})
		}
		
		
		
		//-----------------------------------------------------------------
		//FIN VALIDA EXISTE IDENTIFICACION
		//-----------------------------------------------------------------
		
       
    }
    
	
	renderList() {
			return (this.state.dependencias.map(data =>({label:data.dependencia,value:data.id_dependencia})))
	}
    changeId_tipo_identificacionHandler= (event) => {
        this.setState({id_tipo_identificacion: event.target.value});
    }

    changeIdentificacionHandler= (event) => {
		//alert('aca identifica '+ event.target.value);
        this.setState({identificacion: event.target.value});
    }
	
	blurIdentificacionHandler= (event) => {
		//alert('aca identifica '+ event.target.value);
        //
		
		let errorApp;
		let iden_valida=event.target.value.trim()
		let id_usua = 0;
		if (iden_valida === ''){
			//id_usu_valida = 0;
			alert ('ERROR : La identificación es un campo requerido')
		}else{
			
			if(this.state.id !== '_add'){
				id_usua = this.state.id
						
			}
			
			ErrorAppService.validaExisteIdentificacion(iden_valida, id_usua).then( (res) =>
			{
				//console.log('aki aki '+res.data+'')
		
				errorApp = res.data
				Object.freeze(errorApp)
				//console.log('errorAPP '+errorApp.idErrorApp)
				if(parseInt(errorApp.idErrorApp)>0){
					alert ("ERROR: Verificando "+iden_valida+' '+errorApp.descripcionErrorApp)
					if(this.state.id === '_add'){
						document.getElementById('identificacion').value=''
						//document.getElementById("identificacion").focus();
					}
				}else{
					this.setState({identificacion: iden_valida});				
				}
			})
		}
    }

    changeRazon_socialHandler= (event) => {
        this.setState({razon_social: event.target.value});
    }
    changeEmailHandler= (event) => {
        this.setState({email: event.target.value});
    }
	changeTelefono1Handler= (event) => {
        this.setState({telefono1: event.target.value});
    }
	changeTelefono2Handler= (event) => {
        this.setState({telefono2: event.target.value});
    }
	/*
	changeId_tipo_usuarioHandler= (event) => {
        this.setState({id_tipo_usuario: event.target.value});
    }
	*/
	changeLogueaHandler= (event) => {
        this.setState({loguea: event.target.value});
		
    }

	defaultSelectId_dependencia(id_dependencia){
		//alert ('xxxxid_dependencia '+id_dependencia)
		let opcionesx=this.renderList();
		for (let i=0; i< opcionesx.length; i++){
			if(opcionesx[i].value===id_dependencia){
				
				console.log('depe '+opcionesx[i].label);
				return opcionesx[i]
			}
		}
		return {value:0,label:'sel opcion....'};
	}
    cancel(){
        this.props.history.push('/usuarios');
    }
	/*
	handleChange01(e){
		alert ('Asignando select '+e.value)
	  
		this.setState({id_tipo_identificacion:parseInt(e.value)});
   
		alert('Estado id tipo iden '+this.state.id_tipo_identificacion)
		e.value=this.defaultSelectTipoDoc(this.state.id_tipo_identificacion+'')
		// e.value=this.state.id_tipo_identificacion
   
	}*/
	handleChange(selectedOption) {
		console.log('xxxx'+selectedOption.label)
		let val=selectedOption.value;
		let lbl=selectedOption.label;
		this.setState({id_dependencia : val });
		
		console.log('yyyy '+this.state.selOption_label)
	}
    getTitle(){
        if(this.state.id === '_add'){
            return <h3 className="text-center">Nuevo usuario</h3>
        }else{
            return <h3 className="text-center">Actualiza Usuario</h3>
        }
    }
	
    render() {
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                       
                                        <div className = "form-group">
                                            <label> Identificacion: </label>
                                            <input placeholder="Nro identificacion" required name="identificacion" className="form-control" 
                                                value={this.state.identificacion} id="identificacion" onChange={this.changeIdentificacionHandler} onBlur={this.blurIdentificacionHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Nombre: </label>
                                            <input placeholder="Nombre completo" required name="razon_social" className="form-control" 
                                                value={this.state.razon_social} onChange={this.changeRazon_socialHandler}/>
                                        </div>
										<div className = "form-group">
                                        <label> Email Id: </label>
                                            <input placeholder="suemail@algo.com" type="email" name="email" className="form-control" 
                                                value={this.state.email} onChange={this.changeEmailHandler}/>
                                        </div>
										 <div className = "form-group">
											<label> Telefono 1: </label>
                                            <input placeholder="telefono1" name="telefono1" className="form-control" 
                                                value={this.state.telefono1} onChange={this.changeTelefono1Handler}/>
                                        </div>
										<div className = "form-group">
											<label> Telefono 2: </label>
                                            <input placeholder="telefono2" name="telefono2" className="form-control" 
                                                value={this.state.telefono2} onChange={this.changeTelefono2Handler}/>
                                        </div>
										
									 <div className = "form-group">
                                            <label> Dependencia: </label>
											<Select 
											value={this.defaultSelectId_dependencia(this.state.id_dependencia)}
											options={this.renderList()} 
											onChange={this.handleChange.bind(this)}
											/>
                                        </div>
                                        <button className="btn btn-primary" onClick={this.saveOrUpdateUsuario}>Guardar</button>
                                        <button className="btn btn-secondary" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancelar</button>
                                    </form>
									<br /><br /> <br />
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateUsuarioComponent
