import React from 'react';
//import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import ListEmployeeComponent from './components/ListEmployeeComponent';
import ListUsuarioComponent from './components/ListUsuarioComponent';
import ListReservacionComponent from './components/ListReservacionComponent';
import ListReservacionMasterComponent from './components/ListReservacionMasterComponent';
import ListContratoComponent from './components/ListContratoComponent';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import CreateEmployeeComponent from './components/CreateEmployeeComponent';
import CreateUsuarioComponent from './components/CreateUsuarioComponent';
import CreateReservacionComponent from './components/CreateReservacionComponent';
import CreateReservacionMasterComponent from './components/CreateReservacionMasterComponent';
import CreateContratoComponent from './components/CreateContratoComponent';
import UpdateEmployeeComponent from './components/UpdateEmployeeComponent';
import ViewEmployeeComponent from './components/ViewEmployeeComponent';
import ViewUsuarioComponent from './components/ViewEmployeeComponent';
import ListRecursosReservacionesComponent from'./components/ListRecursosReservacionesComponent';
import ListComponent from './ListComponent';
import Login from './components/LoginComponent';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function App() {
  
  return (
    <div>
        <Router>
              <HeaderComponent />
                <div className="container">

                    <Switch> 
                         
						   <Route path = "/" exact component = {Login}></Route> 
						   <Route path = "/reservacionesMaster" component = {ListReservacionMasterComponent}></Route>
						   
						   <Route path = "/reservaciones" component = {ListReservacionComponent}></Route>
						
						  
						   <Route path = "/recursosReservaciones/:periodo" component = {ListRecursosReservacionesComponent}></Route>
						   <Route path = "/add-reservacion/:id/:cli" component = {CreateReservacionComponent}></Route>
						   <Route path = "/add-reservacionMaster/:id/:cli" component = {CreateReservacionMasterComponent}></Route>
						   <Route path = "/add-contrato/:id/:cli" component = {CreateContratoComponent}></Route>
                          <Route path = "/employees" component = {ListEmployeeComponent}></Route>
                          <Route path = "/add-employee/:id" component = {CreateEmployeeComponent}></Route>
                          <Route path = "/view-employee/:id" component = {ViewEmployeeComponent}></Route>
						  <Route path = "/usuarios" component = {ListUsuarioComponent}></Route> 
						  <Route path = "/contratos" component = {ListContratoComponent}></Route>
						  {/*  <Route path = "/usuarios" component = {ListComponent}></Route>*/}
						   <Route path = "/add-usuario/:id" component = {CreateUsuarioComponent}></Route>
						   <Route path = "/view-usuario/:id" component = {ViewUsuarioComponent}></Route>
							
                          {/* <Route path = "/update-employee/:id" component = {UpdateEmployeeComponent}></Route> */}
                    </Switch>
                </div>
              <FooterComponent />
        </Router>
    </div>
    
  );
}

export default App;
