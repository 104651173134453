import React, { Component } from 'react'

class FooterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }
    }

    render() {
        return (
            <div>
                <footer className = "footer" style={{  position: 'fixed'}}>
                    <span className="text-muted">All Rights Reserved 2022 &copy; IRSOFTWARE SAS</span>
                </footer>
            </div>
        )
    }
}

export default FooterComponent
