import axios from 'axios';

const CONTRATO_API_BASE_URL = "http://club.sintraexito.com:8080/api/v1/contratos";
//const CONTRATO_API_BASE_URL = "http://localhost:8080/api/v1/contratos";
class ContratoService {

    getContratos(){
        return axios.get(CONTRATO_API_BASE_URL);
    }

    createContrato(contrato){
        return axios.post(CONTRATO_API_BASE_URL, contrato);
    }

    getContratoById(contratoId){
        return axios.get(CONTRATO_API_BASE_URL + '/' + contratoId);
    }
   
   updateEstadoContrato( contratoId, estadoId){
		//alert ('actualizando id '+reservacionId+' nuevo estado id '+estadoId )
        return axios.put(CONTRATO_API_BASE_URL + '/' + contratoId+'/'+estadoId);
    }

    updateContrato(contrato, contratoId){
		//alert('id_contratu '+contratoId+ ' ' +JSON.stringify(contrato))
        return axios.put(CONTRATO_API_BASE_URL + '/' + contratoId, contrato);
    }

    deleteContrato(contratoId){
        return axios.delete(CONTRATO_API_BASE_URL + '/' + contratoId);
    }
}

export default new ContratoService()
