import React, { Component } from 'react'
import logo from './logoSintra.png'
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

class HeaderComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            usuario: sessionStorage.getItem('razon_social')   
        }
    }
	salir(){
		sessionStorage.clear();
		this.setState({usuario: null})
	}
	
	
    render() {
		if (sessionStorage.getItem('razon_social') !== null){
        return (
            <div>
                <header>
				<Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand href="#home">
	
	<img src={logo} alt={"logo"} width={"40px"}/> 
	Club Sintraexito APP
	</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="http://club.sintraexito.com/reservacionesMaster">Reservaciones </Nav.Link>
		
		 {/* 
		<Nav.Link href="http://localhost:3000/reservaciones">Reservaciones</Nav.Link>
		*/}
           <Nav.Link href="http://club.sintraexito.com/usuarios">Clientes</Nav.Link>
	   <Nav.Link href="http://club.sintraexito.com/recursosReservaciones/0000-00">Ocupacion</Nav.Link>
	   <Nav.Link href="http://club.sintraexito.com/contratos">Contratos</Nav.Link>
	
	{/*
        <NavDropdown title="Reportes" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown>
	*/}
		<Nav.Link onClick={this.salir} href="/">Salir</Nav.Link>
      </Nav>
    </Navbar.Collapse>
	<Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
         {sessionStorage.getItem('razon_social')}
      </Navbar.Text>
    </Navbar.Collapse>
  </Container>
</Navbar>
			
				</header>
            </div>
        )
		}
		else{
			return (
            <div>
                <header>
			<nav class="navbar navbar-expand-lg navbar-light bg-light">
			  <div class="container-fluid">
				<a class="navbar-brand" href="#">Club Sintraexito APP</a>
				<button
				  class="navbar-toggler"
				  type="button"
				  data-mdb-toggle="collapse"
				  data-mdb-target="#navbarText"
				  aria-controls="navbarText"
				  aria-expanded="false"
				  aria-label="Toggle navigation"
				>
				  <i class="fas fa-bars"></i>
				</button>
				<div class="collapse navbar-collapse" id="navbarText">
				 
				  <span class="navbar-text">
				  {sessionStorage.getItem('razon_social')}
				  </span>
				</div>
			  </div>
			</nav>
			</header>
            </div>
        )
		}
    }
}

export default HeaderComponent
